<template>
  <div class="app">
    <Header></Header>
    <div class="banner" :style="bannerStyle">
      <div class="background">
        <div class="content">
          <div class="left">
            <div class="title">
              <div>房地产<span>商业智能</span>系统</div>
            </div>
            <div class="desc">
              房地产商业智能系统提供功能完善的房地产开发业务管理。可支持系统跨云、虚拟及物力工作负载进行有效扩展，减少人工劳动环节。
            </div>
            <div class="more" @click="changeLayer()">
              <span>立即体验</span>
              <span class="icon font_family jiantou"> &#xe605;</span>
            </div>
          </div>
          <img class="right" :src="bannerLeftImgurl" />
        </div>
      </div>
    </div>
    <div class="contentMain">
      <div class="banner_wrap">
        <!--痛点描述-->
        <img class="despIcon" src="@/assets/crm-desc.png" alt="" />
        <!--轮播-->
        <div class="bannerBg" :style="banner_wrapBg">
          <swiper class="swiperBanner" :options="swiperOptions">
            <swiper-slide
              class="slideList"
              v-for="(item, index) in bannerList"
              :key="index"
            >
              <div class="zz">{{ item.name }}</div>
              <img class="bgImg" :src="item.img" mode="" />
              <div class="mask active"></div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!--功能优势-->
      <div class="advan_wrap">
        <img class="despIcon" src="@/assets/crm-gnys.png" alt="" />
        <div class="advanList" v-for="(item, index) in despList" :key="index">
          <img :src="item.img" alt="" />
          <div class="despBox">
            <p>{{ item.name }}</p>
            <p>{{ item.desp }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <div class="maskLayer" v-show="layer">
      <div class="layer-content">
        <div class="close" @click="closeLayer()">
          <img src="@/assets/close.png" alt="" />
        </div>
        <div class="productList">
          <div class="header">在线咨询</div>
          <div class="content">
            <div class="zx-item">
              <input
                class="zx-input"
                v-model="formItem.username"
                placeholder="请输入姓名"
              />
              <input
                class="zx-input"
                v-model="formItem.telphone"
                placeholder="请输入手机号码"
              />
              <input
                class="zx-input"
                v-model="formItem.product"
                placeholder="你想了解哪个商品"
              />
            </div>
            <div class="sub" @click="sub">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sendEmail } from "@/untils/index";

import "@/assets/css/iconfont.css";
import Header from "@/components/PC/Header.vue";
import Footer from "@/components/PC/Footer.vue";

import "@/style/style.scss";
export default {
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      layer: false,
      formItem: {},
      bannerBg: require("@/assets/bannerBg.png"),
      banner_wrapBg: {},
      despList: [
        {
          img: require("@/assets/product/syzn/y-1.png"),
          name: "项目数字化管理",
          desp: "数字化管理项目信息，整理更便捷，信息更完整",
        },
        {
          img: require("@/assets/product/syzn/y-2.png"),
          name: "简洁的业务流程",
          desp: "简化业务流程，操作更省时",
        },
        {
          img: require("@/assets/product/syzn/y-3.png"),
          name: "合规化业务流程",
          desp: "制定业务规范操作，避免不合规行为",
        },
      ],
      bannerList: [
        {
          img: require("@/assets/product/syzn/1.png"),
          name: "项目信息整理粗糙",
        },
        {
          img: require("@/assets/product/syzn/2.png"),
          name: "人力管理成本高",
        },
        {
          img: require("@/assets/product/syzn/3.png"),
          name: "流程繁琐",
        },
        {
          img: require("@/assets/product/syzn/4.png"),
          name: "项目综合管理困难",
        },
      ],
      bgImgUrl: require("@/assets/crmBg.png"),
      swiperbgImgUrl: require("@/assets/crm-desc.png"),
      swiperbg1: require("@/assets/crm-swiper-1.png"),
      swiperbg2: require("@/assets/crm-swiper-2.png"),
      swiperbg3: require("@/assets/crm-swiper-3.png"),
      swiperbg: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        left: "40%",
      },
      bannerLeftImgurl: require("@/assets/index-pro-商业智能.png"),

      bannerStyle: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
      section1: {
        backgroundImage: "url(" + require("@/assets/crm_body_left_1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "400px",
      },
      section2: {
        backgroundImage: "url(" + require("@/assets/crm_body_right_1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "400px",
      },
      section3: {
        backgroundImage: "url(" + require("@/assets/crm_body_left_2.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "400px",
      },
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: false,
        centeredSlides: false,
        loop: true,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    handleScroll(e) {
      var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
      console.log(scrollTop);
    },
    changeLayer() {
      this.layer = !this.layer;
    },
    closeLayer() {
      this.layer = false;
    },
    sub() {
      sendEmail(this.formItem)
        .then((response) => {
          let code = response.code;
          let msg = response.msg;
          if (code != 200) {
            this.$Notice.error({
              title: msg,
            });
          } else {
            this.layer = false;
            this.formItem = {};
            this.$Notice.success({
              title: msg,
            });
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
  created() {
    this.bannerStyle.backgroundImage = " url(" + this.bgImgUrl + ")";
    this.swiperbg.backgroundImage = " url(" + this.swiperbgImgUrl + ")";
    this.banner_wrapBg.backgroundImage = " url(" + this.bannerBg + ")";
  },
};
</script>

<style lang="scss">
.app {
  background-color: #f5f5f5;
  .banner {
    background-color: transparent;

    .background {
      height: 660px;
      width: 100%;
      .content {
        padding-top: 220px;
        width: 1200px;
        margin: 0 auto;
        font-size: 20px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        .left {
          display: flex;
          flex-direction: column;
          .title {
            height: 45px;
            font-size: 48px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 34px;
            span {
              color: #03958c;
            }
          }

          .desc {
            text-align: left;
            margin-top: 22px;
            width: 518px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #cccccc;
            height: 88px;
          }
          .more {
            margin-top: 66px;
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            border-top: 48px solid #03958c;
            border-left: 0px solid transparent;
            border-right: 25px solid transparent;
            height: 200px;
            width: 200px;
            position: relative;
            display: flex;
            justify-content: space-between;
            span {
              text-align: center;
              position: absolute;
              top: -35px;
              left: 40px;
            }
            .jiantou {
              font-size: 42px;
              color: #f5b00c;
              text-align: center;
              position: absolute;
              top: -52px;
              left: 120px;
            }
          }
        }
        .right {
          margin-top: -50px;
          margin-left: 120px;
          width: 317px;
          height: 311px;
        }
      }
    }
  }
  .contentMain {
    margin-top: 46px;
    .banner_wrap {
      position: relative;
      height: 700px;
      .despIcon {
        width: 736px;
        height: 382px;
        position: absolute;
        left: 390px;
      }
      .bannerBg {
        min-width: 846px;
        width: 864px;
        height: 480px;
        background-size: cover;
        margin-top: 167px;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        .slideList {
          position: relative;
          width: 240px;
          height: 255px;
          .zz {
            font-size: 18px;
            color: #fff;
            position: absolute;
            z-index: 2;
            width: 240px;
            height: 255px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .bgImg {
            width: 240px;
            height: 255px;
          }
          .mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 240px;
            height: 255px;
          }
        }
        .swiper-slide-active {
          width: 320px;
          height: 340px;
          margin-right: 80px;
          .zz,
          .bgImg,
          .mask {
            width: 320px;
            height: 340px;
          }
          .mask {
            background: rgba(3, 148, 138, 0.8);
          }
        }
        .swiperBanner {
          .swiper-wrapper {
            display: flex !important;
            align-items: center !important;
          }
        }
      }
    }
    .advan_wrap {
      .despIcon {
        width: 563px;
        height: 267px;
        display: flex;
        margin: 0 auto;
      }
      .advanList {
        display: flex;
        align-items: center;
        margin-bottom: 80px;
        img {
          width: 60%;
        }
        .despBox {
          width: 40%;
          padding-left: 10%;
          p {
            font-size: 24px;
            color: #333;
          }
          p:last-child {
            font-size: 14px;
            color: #999;
            margin-top: 40px;
          }
        }
      }
      .advanList:nth-child(2n + 1) {
        flex-direction: row-reverse;
      }
    }
  }
  .maskLayer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    outline: 0;
    -webkit-overflow-scrolling: touch;
    background-color: rgb(0, 0, 0);
    filter: alpha(opacity=60);
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    display: flex;
    justify-content: center; /*实现水平居中*/
    align-items: center; /*实现垂直居中*/
    .layer-content {
      background-color: #ffffff;
      height: 500px;
      position: relative; /*脱离文档流*/
      width: 500px;
      margin: 0 auto;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .close {
        display: flex;
        justify-content: flex-end;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .productList {
        display: flex;
        flex-wrap: wrap;
        flex-flow: row wrap;

        /* !当主轴沿水平方向时!justify-content,决定item在主轴上的对齐方式，可能的值有flex-start（默认），flex-end，center，space-between，space-around */
        justify-content: center;
        /* !主轴水平时!决定了item在交叉轴上的对齐方式，可能的值有flex-start|flex-end|center|baseline|stretch */
        align-items: center;
        text-align: center;
        .header {
          height: 29px;
          font-size: 30px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 34px;
          text-align: center;
          align-items: center;
        }
        .content {
          width: 1200px;
          margin: 0 auto;
          text-align: center;

          .zx-item {
            display: flex;
            flex-direction: column;
            // margin-left: 120px;
            text-align: center;
            align-items: center;
            .zx-input {
              outline: none;

              width: 460px;
              height: 60px;
              margin-top: 21px;
              // margin-right: 24px;
              border: 0px;

              padding-left: 25px;
              border: 1px solid #03958c;
            }
            .zx-input:focus {
              outline: none;
              border: 1px solid #03958c;
            }
          }
        }
        .sub {
          text-align: center;
          margin: 0 auto;
          margin-top: 60px;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          background-color: #03958c;
          width: 90%;
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
}
</style>
