<template>
  <div class="app">
    <div class="conetnt">
      <div class="title"><h2>APP隐私政策</h2></div>
      <Divider />

      <div class="body">
        <p>
          欢迎您使用楼东东（以下简称“本软件”），楼东东是由成都扬帆启初科技有限公司（以下简称“本公司”）向房地产行业客户提供的软件服务。为保证您的权益，便于更好地使用本软件服务，请您务必在使用前认真阅读本协议，若您阅读并接受本协议，使用本软件提供的产品和服务，即视为您受本协议的约束，若您不同意本协议，请勿使用本软件任何产品和服务。
          本软件尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本软件会按照本隐私权政策的规定使用和披露您的个人信息。但本软件将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本软件不会将这些信息对外披露或向第三方提供。本软件会不时更新本隐私权政策。您在同意本软件服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本软件服务使用协议不可分割的一部分。
        </p>
        <Divider />
        <p>1.适用范围</p>
        <p>
          a)在您使用本软件网络服务，本软件会接收记录您提交的信息，不会记录您的手机上的信息，包括但不限于您的位置信息、相册和相机的访问、使用的语言、访问日期和时间、软硬件特征信息等数据。
        </p>
        <Divider />
        <p>2.信息的使用</p>
        <p>
          a)在获得您提交的数据之后，本软件会将其上传至服务器，以便您能够更好地使用服务；
        </p>
        <Divider />
        <p>3.信息披露</p>
        <p>
          a)本软件不会将您的信息披露给不受信任的第三方。
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
          如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；
        </p>
        <Divider />
        <p>4.信息存储和交换</p>
        <p>
          本软件收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上；
        </p>
        <Divider />
        <p>5.信息安全</p>
        <p>
          a)本软件提供您所属设备的信息查询功能，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本软件客服，以便本软件采取相应措施。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {},
  // 定义属性
  data() {
    return {};
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (
      /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
        navigator.userAgent
      )
    ) {
      this.$router.replace("/h5/conceal");

      // window.location.href = "/#/h5/conceal";
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
/deep/ body {
  background-color: #ffffff !important;
}
.app {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
  color: #000000;

  .conetnt {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    .title {
      margin: 0 auto;
      padding-top: 20px;
      align-items: center;
    }
    .body {
      text-align: left;
    }
  }
}
</style>
