import axios from 'axios'
import router from '../router/index.js'

// 请求拦截
axios.interceptors.request.use(
    (confing) => {
        console.log(localStorage.accessToken)
        //设置请求头
        if (localStorage.accessToken) {
            confing.headers.Authorization = "Bearer "+localStorage.accessToken
        }
        return confing
    },
    (error) => {
        return Promise.reject(error)
    }
)

//响应拦截

axios.interceptors.response.use(
    (response) => {
        let code  = response.data.code
        if (code === 401) {
            //清除token
            localStorage.removeItem('accessToken')
            //跳转到登录页面
            router.push('/login')
        }
        return response.data
    },
    (error) => {
        // 获取状态码
        return Promise.reject(error)
    }
)
export default axios