<template>
  <div class="app">
    <div class="conetnt">
      <div class="title"><h4>楼东东用户使用服务协议</h4></div>
      <Divider />

      <div class="body">
        <p>一、特别提示</p>
        <p>
          在此特别提醒您（用户）在注册成为楼东东用户之前，请认真阅读本《楼东东用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您同意并点击确认本协议条款且完成注册程序后，才能成为楼东东的正式注册用户，并享受楼东东的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止楼东东用户注册程序，并可以选择不使用本网站服务。
          本协议约定楼东东与用户之间关于“楼东东”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由楼东东随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本APP中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用楼东东提供的服务，用户继续使用楼东东提供的服务将被视为接受修改后的协议。
        </p>
        <Divider />
        <p>二、账号注册</p>
        <p>
          1、用户在使用本服务前需要注册一个“楼东东”账号。“楼东东”账号应当使用手机号码绑定注册，请用户使用尚未与“楼东东”账号绑定的手机号码，以及未被楼东东根据本协议封禁的手机号码注册“楼东东”账号。楼东东可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
          <Divider />
        </p>
        <p>
          2、如果注册申请者有被楼东东封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，楼东东将拒绝其注册申请。
          <Divider />
        </p>
        <p>
          3、鉴于“楼东东”账号的绑定注册方式,您同意楼东东在注册时将允许您的手机号码及手机设备识别码等信息用于注册。
          <Divider />
        </p>
        <p>
          4、在用户注册及使用本服务时,楼东东需要搜集能识别用户身份的个人信息以便楼东东可以在必要时联系用户,或为用户提供更好的使用体验。楼东东搜集的信息包括但不限于用户的姓名、地址;楼东东同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
          <Divider />
        </p>

        <p>三、账户安全</p>
        <p>
          1、用户一旦注册成功,成为楼东东的用户,将得到一个用户名和密码,并有权利使用自己的用户名及密码随时进行登陆**
        </p>
        <p>
          2、用户对用户名和密码的安全负全部责任,同时对以其用户名进行的所有活动和事件负全责。
        </p>
        <p>3、用户不得以任何形式擅自转让或授权他人使用自己的楼东东用户名</p>
        <p>
          4、用户对密码加以妥善保管,切勿将密码告知他人,因密码保管不善而造成的所有损失由用户自行承担。
        </p>
        <p>
          5、如果用户泄漏了密码,有可能导致不利的法律后果,因此不管任何原因导致用户的密码安全受到威胁,应该立即和楼东东客服人员取得联系,否则后果自负。
        </p>
        <Divider />
        <p>四、用户声明与保证</p>
        <p>
          1、用户承诺其为具有完全民事行为能力的民事主体,且具有达成交易履行其义务的能力。
        </p>
        <Divider />

        <p>
          2、用户有义务在注册时提供自己的真实资料,并保证诸如手机号码、姓名、所在地区等内容的有效性及安全性,保证楼东东工作人员可以通过上述联系方式与用户取得联系。同时,用户也有义务在相关资料实际变更时及时更新有关注册资料。
        </p>
        <Divider />

        <p>
          3、用户通过使用楼东东的过程中所制作、上载、复制、发布、传播的任何內容,包括但不限于账号头像、名称、用户说明等注册信息及认证资料,或文字、语音、图片、视频、图文等发送、回复和相关链接页面,以及其他使用账号或本服务所产生的内容,不得违反国家相关法律制度,包含但不限于如下原则:
        </p>
        <p>
          (1)违反宪法所确定的基本原则的；
          <br />
          (2)危害国家安全,泄露国家秘密,颠覆国家政权,破坏国家统一的；
          <br />
          (3)损害国家荣誉和利益的； (4)煽动民族仇恨、民族歧视,破坏民族团结的；
          <br />
          (5)破坏国家宗教政策,宣扬邪教和封建迷信的；
          <br />
          (6)散布谣言,扰乱社公秩序,破坏社会稳定的；
          <br />
          (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          <br />
          (8)侮辱或者诽谤他人,侵害他人合法权益的；
          <br />
          (9)含有法律、行政法规禁止的其他内容的。
          <br />
        </p>
        <p>
          4、用户不得利用“楼东东”账号或本服务制作、上载、复制、发布、传播下干扰“楼东东”的正常运营,以及侵犯其他用户或第三方合法权益的内容：
        </p>
        <p>
          (1)含有任何性或性暗示的； <br />
          (2)含有辱骂、恐吓、威胁内容的； <br />
          (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的； <br />
          (4)涉及他人隐私、个人信息或资料的； <br />
          (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的； <br />
          (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
          <br />
        </p>
        <p>五、服务内容</p>
        <p>
          1、楼东东是由成都扬帆启初科技有限公司提供的在在线房企CRM管理平台,具体服务内容由楼东东根据实际情况提供,包括但不限于:
        </p>

        <p>
          (1)待办：客户认领、客户邀约、业务单、我的申请、预约到访、待回访、逾期未跟进、逾期催办；
          <br />
          (2)客户：客户详情、调查问卷； <br />
          (3)房源：在售房源、预售房源；
          <br />

          (4)更多：视觉切换、项目切换、业绩、用户信息；
          <br />
        </p>
        <Divider />

        <p>
          2、楼东东有权随时审核或删除用户发布/传播的涉嫌违法或违反社会主义精神文明,或者被楼东东认为不妥当的内容(包括但不限于文字、语音、图片、视频图文等)。
        </p>
        <Divider />
        <p>六、服务的终止</p>
        <p>1、在下列情况下,楼东东有权终止向用户提供服务；</p>
        <p>
          （1）在用户违反本服务协议相关规定时，楼东东有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，楼东东有权直接单方面终止向该用户提供服务；
        </p>
        <p>
          （2）如楼东东通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，楼东东以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，楼东东有权终止向该用户提供服务；
        </p>
        <p>
          （3）一旦楼东东发现用户提供的数据或信息中含有虚假内容，楼东东有权随时终止向该用户提供服务；
        </p>
        <p>（4）本服务条款终止或更新时，用户明示不愿接受新的服务条款；</p>
        <p>（5）以及其他楼东东认为需要终止服务的情况。</p>
        <p>
          <Divider />
          2、服务终止后，楼东东没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
        </p>
        <Divider />
        <p>3、用户理解并同意，即便在本协议终止及用户的服务被终止后，楼东东仍有权：</p>
        <p>
          （1）继续续保存您的用户信息；
          （2）继续向用户主张在其使用本平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。
        </p>
        <Divider />
        <p>七、服务的变更、中断</p>
        <p>
          1、鉴于网络服务的特殊性，用户需同意楼东东会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
        </p>
        <Divider />
        <p>
          2、楼东东需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，楼东东无需为此承担任何责任。
        </p>
        <Divider />
        <p>八、服务条款修改</p>
        <p>
          1、楼东东有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，楼东东将会通过适当方式向用户提示修改内容。
        </p>
        <Divider />
        <p>2、如果不同意楼东东对本服务条款所做的修改，用户有权停止使用网络服务。</p>
        <Divider />
        <p>3、如果用户继续使用网络服务，则视为用户接受楼东东对本服务条款所做的修改。</p>
        <Divider />
        <p>九、免责与赔偿声明</p>
        <p>
          1、若楼东东已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
        </p>
        <Divider />
        <p>
          2、用户明确同意其使用楼东东所存在的风险将完全由其自己承担，因其使用楼东东而产生的一切后果也由其自己承担。
        </p>
        <Divider />
        <p>
          3、用户同意保障和维护楼东东及其他用户的利益，由于用户在使用楼东东有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给楼东东及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。
        </p>
        <Divider />
        <p>十、隐私声明</p>
        <p>1.适用范围</p>

        <p>
          在您使用本软件网络服务，本软件会接收记录您提交的信息，不会记录您的手机上的信息，包括但不限于您的位置信息、相册和相机的访问、使用的语言、访问日期和时间、软硬件特征信息等数据。
        </p>
        <Divider />

        <p>2.信息的使用</p>
        <p>
          在获得您提交的数据之后，本软件会将其上传至服务器，以便您能够更好地使用服务；
        </p>
        <Divider />

        <p>3.信息披露</p>
        <p>
          本软件不会将您的信息披露给不受信任的第三方。
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
          如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；
        </p>
        <Divider />

        <p>4.信息存储和交换</p>
        <p>本软件收集的有关您的信息和资料将保存在本软件及（或）其关联公司的服务器上；</p>
        <Divider />

        <p>5.信息安全</p>
        <p>
          本软件提供您所属设备的信息查询功能，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本软件客服，以便本软件采取相应措施。
        </p>
        <Divider />

        <p>十一、其他</p>
        <p>
          1、楼东东郑重提醒用户注意本协议中免除楼东东来责任和和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
        </p>
        <Divider />

        <p>
          2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和楼东东之间发生任何纠纷或争议，首先应友好协解决，协商不成的，用户同意将纠纷或争议提交楼东东住所地有管辖权的人民法院管辖。
        </p>
        <Divider />

        <p>
          3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        </p>
        <Divider />

        <p>4、本协议最终解释权归楼东东有限公司所有，并且保留一切解释和修改的权力。</p>
        <Divider />

        <p>5、本协议自2021年7月1日起适用。</p>
        <Divider />
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {},
  // 定义属性
  data() {
    return {};
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
p {
  font-size: 1rem;
}
.app {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
  color: #000000;

  .conetnt {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    .title {
      margin: 0 auto;
      padding-top: 20px;
      align-items: center;
    }
    .body {
      text-align: left;
    }
  }
}
</style>
