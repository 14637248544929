<template>
  <div class="app">
    <Header :active="activeName"></Header>
    <div class="banner" :style="bannerStyle">
      <div class="background">
        <div class="content">
          <div class="left">
            <div class="name">
              <i><b>SERVER</b></i>
            </div>
            <div class="title">
              <div>服务支持</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tip">
      <img src="@/assets/service/general.png" />
    </div>

    <div class="general">
      <div class="section">
        <img src="@/assets/service/kefu.png" />
        <div class="text">
          <div class="title">客服</div>
          <div class="info">
            专业客服全天24小时在线提供不间断服务，第一时间处理售前售后问题，故障报修第一时间安排专人处理
          </div>
        </div>
      </div>
      <div class="section">
        <img src="@/assets/service/zixun.png" />
        <div class="text">
          <div class="title">售前咨询</div>
          <div class="info">
            了解客户需求及时答疑，安排专人进行远程演示和在线体验服务，帮助项目更好的体现价值
          </div>
        </div>
      </div>
      <div class="section">
        <img src="@/assets/service/souhou.png" />
        <div class="text">
          <div class="title">售后</div>
          <div class="info">
            专业技术团队第一时间帮您解决产品以及使用问题，实施之后有专人培训，保证产品正常使用
          </div>
        </div>
      </div>
    </div>

    <div class="response">
      <img src="@/assets/service/response.png" alt="" />
      <div class="level" :style="down"></div>
    </div>

    <div class="tip">
      <img src="@/assets/service/policy.png" />
    </div>

    <div class="policy">
      <Row :gutter="16" type="flex" justify="space-between" class="row">
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe602;</span>
            </div>
            <div class="right">
              <div class="title">全过程跟进管理</div>
              <div class="info">
                对用户的服务请求从“受理---派单---预约---过程反馈---结果反馈---回访闭环”进行全过程跟进管理，让您真正用得舒心！
              </div>
            </div>
          </div>
        </Col>
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe604;</span>
            </div>
            <div class="right">
              <div class="title">极速响应高效处理</div>
              <div class="info">
                受理后1小时内有专业的售后工程师响应提供服务，在2个工作日内回复或确认初步解决方案！根据问题严重度，可电话沟通、远程解决或者主动巡检！
              </div>
            </div>
          </div></Col
        >
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe606;</span>
            </div>
            <div class="right">
              <div class="title">送装一体服务</div>
              <div class="info">
                我们整合产品配送和安装，为您提供一次性上门送货－安装－调试，让您享受一步到位的贴心体验！
              </div>
            </div>
          </div></Col
        >
      </Row>

      <Row :gutter="16" type="flex" justify="space-between" class="row">
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe607;</span>
            </div>
            <div class="right">
              <div class="title">应用培训</div>
              <div class="info">
                专人在现场进行培训指导，协助用户更快的适应环境和了解操作流程，协助企业员工快速上手！
              </div>
            </div>
          </div>
        </Col>
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe608;</span>
            </div>
            <div class="right">
              <div class="title">故障诊断与处理</div>
              <div class="info">
                对于故障诊断处理我们有专业的工程师进行对接：问题诊断与评估；问题处理与反馈；服务过程进行归档；最后进行应用满意度回访！
              </div>
            </div>
          </div></Col
        >
        <Col span="6"
          ><div class="section">
            <div class="left">
              <span class="icon font_family"> &#xe60a; </span>
            </div>
            <div class="right">
              <div class="title">系统增值服务</div>
              <div class="info">
                项目可提供拓展定制化开发、数据安全与管理、应用整合等服务，让产品更懂您，使用更舒心！
              </div>
            </div>
          </div></Col
        >
      </Row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import "@/assets/css/iconfont.css";
import Header from "@/components/PC/Header.vue";
import Footer from "@/components/PC/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      activeName: "5",
      bgImgUrl: require("@/assets/about/banner.png"),
      dnonImgUrl: require("@/assets/service/down.png"),

      bannerStyle: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
      down: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = " url(" + this.bgImgUrl + ")";
    this.down.backgroundImage = " url(" + this.dnonImgUrl + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5;

  .banner {
    background-color: transparent;
    .bgImg {
      height: 100%;
      width: 100%;
    }
    .background {
      height: 660px;
      width: 100%;
      .content {
        padding-top: 220px;
        width: 1200px;
        margin: 0 auto;
        font-size: 20px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        .left {
          display: flex;
          flex-direction: column;
          .name {
            margin-left: 100px;
            height: 17px;
            font-size: 24px;
            font-family: Helvetica-BoldOblique;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            opacity: 0.3;
          }
          .title {
            margin-top: 40px;
            margin-left: 100px;
            height: 45px;
            font-size: 48px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 34px;
            span {
              color: #03958c;
            }
          }
        }
      }
    }
  }

  .general {
    margin-top: 60px;
    margin: 0 auto;
    text-align: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    width: 1200px;
    .section {
      display: flex;
      flex-direction: column;
      .sectionImg {
        margin: 0 auto;
        text-align: center;
        img {
          width: 210px;
          height: 220px;
          text-align: center;
        }
      }
      .text {
        width: 285px;
        height: 84px;
        .title {
          margin-top: 20px;
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
        }
        .info {
          margin-top: 12px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 23px;
        }
      }
    }
  }

  .tip {
    margin: 0 auto;
    text-align: center;
    margin-top: 108px;
    .desc {
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
      width: 1093px;
      height: 70px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      span {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .response {
    position: relative;
    margin: 0 auto;
    text-align: center;
    height: 400px;
    margin-top: 140px;
    margin-bottom: 400px;

    background: #14161c;
    img {
      margin-top: 90px;
    }

    .level {
      width: 1200px;
      height: 350px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .section {
        width: 540px;
        height: 130px;
        background: #c3c3c3;
      }
    }
  }

  .row {
    margin-top: 40px;
  }
  .policy {
    width: 1200px;
    margin: 0 auto;
    .section {
      display: flex;
      justify-content: flex-start;
      width: 372px;
      height: 213px;
      background: #ffffff;
      box-shadow: 0px 0px 50px 0px rgba(144, 144, 144, 0.14);
      padding: 20px;
      .left {
        span {
          font-size: 40px;
          color: #03958c;
        }
      }
      .right {
        .title {
          text-align: left;
          margin-top: 20px;
          margin-left: 30px;
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
        }
        .info {
          margin-left: 30px;
          margin-top: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
      }
    }
    .section:hover {
      border: 0.04rem solid #03958c;
    }
  }
}
</style>
