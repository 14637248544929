<template>
  <div class="app">
    <div class="zx">
      <div class="zxImg">
        <img src="@/assets/home-zxzx.png" />
      </div>
      <div class="zx-content">
        <div class="zx-item">
          <input class="zx-input" v-model="form.username" placeholder="请输入姓名" />
          <input class="zx-input" v-model="form.telphone" placeholder="请输入手机号码" />
        </div>
        <div class="zx-item">
          <input class="zx-input" v-model="form.email" placeholder="请输入邮箱" />
          <input class="zx-input" v-model="form.product" placeholder="您想了解哪个产品" />
        </div>
      </div>

      <div class="sub" @click="subForm">
        <span>立即预约</span>
        <span class="icon font_family jiantou"> &#xe605;</span>
      </div>
    </div>

    <Footer class="footer">
      <div class="content">
        <div class="footer-hd">
          <div class="info">
            <img class="logo" src="@/assets/logo.png" />

            <div class="nav">
              <div
                class="item"
                v-for="(item, index) in list"
                :key="index"
                @click="go(item.url)"
              >
                <span> {{ item.name }}</span>
              </div>

              <div class="item">
                <span>
                  <p>全国热线</p>
                  <p class="telphone"><i>028-62094278</i></p>
                </span>
              </div>
            </div>
          </div>

          <div class="qrcode"><img src="@/assets/qrcode.png" /></div>
        </div>
        <div class="footer-fd">蜀ICP备19020384号</div>
      </div>
    </Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { addMsg } from "../../untils/index";
import "@/assets/css/iconfont.css";

export default {
  props: ["data"],

  // 定义属性
  data() {
    return {
      form: {
        username: "",
        email: "",
        telphone: "",
        product: "",
      },
      list: [
        {
          name: "首页",
          url: "/",
        },

        {
          name: "关于我们",
          url: "/about",
        },
        {
          name: "渠道合作",
          url: "/cooperation",
        },
        {
          name: "服务支持",
          url: "/service",
        },
        {
          name: "隐私政策",
          url: "/conceal",
        },
        {
          name: "服务协议",
          url: "/userconceal",
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    subForm() {
      addMsg(this.form)
        .then((response) => {
          let code = response.code;
          let msg = response.msg;
          if (code != 200) {
            this.$Notice.error({
              title: msg,
            });
          } else {
            this.$Notice.success({
              title: msg,
            });
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  .zx {
    width: 100%;
    .zxImg {
      width: 1200px;
      text-align: center;
      margin: 0 auto;
    }
    .zx-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;

      .zx-item {
        display: flex;
        justify-content: flex-start;
        margin-left: 120px;
        .zx-input {
          outline: none;
          width: 460px;
          height: 56px;
          margin-top: 21px;
          margin-right: 24px;
          border: 0px;

          padding-left: 25px;
          border: 1px solid #03958c;
        }
        .zx-input:focus {
          outline: none;
          border: 1px solid #03958c;
        }
      }
    }

    .sub {
      width: 1200px;
      text-align: center;
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 100px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      border-top: 48px solid #03958c;
      border-left: 0px solid transparent;
      border-right: 25px solid transparent;
      height: 200rpx;
      width: 200px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      span {
        text-align: left;
        position: absolute;
        top: -35px;
        left: 30px;
      }
      .jiantou {
        font-size: 42px;
        color: #f5b00c;
        text-align: right;
        position: absolute;
        top: -52px;
        left: 110px;
      }
    }
  }
}

.footer {
  background: #14161c;
  display: flex;
  flex-direction: column;

  .content {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    .footer-hd {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .info {
        display: flex;
        flex-direction: column;
        .logo {
          width: 180px;
          height: 60px;
        }
        .nav {
          display: flex;
          justify-content: flex-start;
          text-align: left;
          .item {
            font-size: 16px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            padding-right: 60px;
            display: flex;
            flex-direction: column;
          }
        }
      }
      .qrcode {
        img {
          width: 160px;
          height: 160px;
        }
      }
    }

    .footer-fd {
      text-align: center;
    }
    .phone {
      // text-align: left;
      width: 300px;
      .telphone {
        height: 21px;
        font-size: 24px;
        font-family: Helvetica-BoldOblique;
        font-weight: 400;
        color: #f5b00c;
        line-height: 57px;
      }
    }
  }
  .telphone {
    font-size: 24px;
    font-family: Helvetica-BoldOblique;
    font-weight: 400;
    color: #f5b00c;
  }
}
</style>
