<template>
  <div class="app">
    <Header :active="activeName"></Header>
    <div class="banner" :style="bannerStyle">
      <div class="background">
        <div class="content">
          <div class="left">
            <div class="name">
              <i><b>COOPERATION</b></i>
            </div>
            <div class="title">
              <div>渠道合作</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tip">
      <img src="@/assets/cooperation/goodness.png" />
    </div>
    <div class="tip2">
      <img src="@/assets/cooperation/img1.png" />
    </div>
    <div class="tip3">
      <img src="@/assets/cooperation/img2.png" />
    </div>
    <div class="tip4">
      <img src="@/assets/cooperation/img3.png" />
    </div>

    <div class="step">
      <img src="@/assets/cooperation/step.png" alt="" />
      <div class="many">
        <div class="section">
          <img src="@/assets/cooperation/step1.png" alt="" />
          <div class="text">
            <div class="title">确定意向</div>
            <div class="info">联系渠道经理确认合作意向</div>
          </div>
        </div>
        <div class="section">
          <img src="@/assets/cooperation/step2.png" alt="" />
          <div class="text">
            <div class="title">签署协议</div>
            <div class="info">和渠道经理详谈合同事宜，签署合作协议</div>
          </div>
        </div>
        <div class="section">
          <img src="@/assets/cooperation/step3.png" alt="" />
          <div class="text">
            <div class="title">配置服务</div>
            <div class="info">总部配置渠道合作及相关服务和售后</div>
          </div>
        </div>
        <div class="section">
          <img src="@/assets/cooperation/step4.png" alt="" />
          <div class="text">
            <div class="title">现场实施</div>
            <div class="info">快速展开当地销售工作</div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/PC/Header.vue";
import Footer from "@/components/PC/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      activeName: "4",
      bgImgUrl: require("@/assets/about/banner.png"),
      bannerStyle: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = " url(" + this.bgImgUrl + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5;

  .banner {
    background-color: transparent;
    .bgImg {
      height: 100%;
      width: 100%;
    }
    .background {
      height: 660px;
      width: 100%;
      .content {
        padding-top: 220px;
        width: 1200px;
        margin: 0 auto;
        font-size: 20px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        .left {
          display: flex;
          flex-direction: column;
          .name {
            margin-left: 100px;
            height: 17px;
            font-size: 24px;
            font-family: Helvetica-BoldOblique;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            opacity: 0.3;
          }

          .title {
            margin-top: 40px;
            margin-left: 100px;

            height: 45px;
            font-size: 48px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 34px;
            span {
              color: #03958c;
            }
          }
        }
      }
    }
  }

  .tip {
    margin: 0 auto;
    text-align: center;
    margin-top: 108px;
  }
  .tip2 {
    margin: 0 auto;
    text-align: center;
    margin-top: 70px;
  }
  .tip3 {
    margin: 0 auto;
    text-align: center;
    margin-top: 70px;
  }
  .tip4 {
    margin: 0 auto;
    text-align: center;
    margin-top: 70px;
  }
  .step {
    position: relative;
    margin: 0 auto;
    text-align: center;
    height: 400px;
    margin-top: 140px;
    margin-bottom: 400px;

    background: #14161c;
    img {
      margin-top: 90px;
    }
  }

  .many {
    margin: 0 auto;
    text-align: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    width: 1200px;
    .section {
      display: flex;
      flex-direction: column;
      -moz-transition: margin-top 1s; /* Firefox 4 */
      -webkit-transition: margin-top 1s; /* Safari and Chrome */
      -o-transition: margin-top 1s; /* Opera */
      img {
        width: 285px;
        height: 220px;
        transition: margin-top 1s;
      }
      .text {
        width: 285px;
        height: 84px;
        background-color: #ffffff;
        .title {
          margin-top: 20px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
        .info {
          margin-top: 12px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
        }
      }
    }
    .section:hover {
      margin-top: -30px;
    }
  }
}
</style>
