<template>
  <div class="app">
    <Header></Header>
    <div class="banner" :style="bannerStyle">
      <div class="info">
        <div class="text">
          <div class="left">持续<br />引领</div>
          <div class="right">地产<br />营销升级</div>
        </div>
        <div class="desc">
          持续引领地产营销升级持续引领地产营销升级持续引领地产营销升级
        </div>
        <div class="more" @click="go('h5/more')">
          <span>查看详情</span>
          <span class="icon font_family jiantou"> &#xe605;</span>
        </div>
      </div>
    </div>

    <div class="ddky">
      <img class="ddkyimg" src="@/assets/h5/index/ddky.png" />
    </div>

    <div class="section">
      <div class="item">
        <img src="@/assets/h5/index/pc.png" />
        <div class="text">PC端</div>
      </div>
      <div class="item">
        <img src="@/assets/h5/index/app.png" />
        <div class="text">APP</div>
      </div>
      <div class="item">
        <img src="@/assets/h5/index/xcx.png" />
        <div class="text">小程序</div>
      </div>
    </div>

    <div class="cpjz">
      <img class="cpjzImg" src="@/assets/h5/index/cpjz.png" />
      <div class="section">
        <div
          class="listV"
          v-for="(item, index) in list"
          :key="index"
          @click="go(item.url)"
        >
          <img :src="item.img" alt="" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="body">
              <div
                class="list"
                v-for="(descItem, descIndex) in item.desc"
                :key="descIndex"
              >
                <span></span>
                <div class="desc">{{ descItem.name }}</div>
              </div>
            </div>
            <div class="click">
              <img src="@/assets/h5/click.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="getmore">
      <div class="more" @click="go('/h5/more')">
        <span>查看全部</span>
        <span class="icon font_family jiantou"> &#xe605;</span>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";

export default {
  props: ["data"],
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/index/h5banner.png"),
      bannerStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "30.83rem",
      },
      list: [
        {
          img: require("@/assets/h5/index/item-crm.png"),
          url: "/h5/product/crm",
          title: "房地产CRM营销管理系统",
          desc: [{ name: "大数据营销管理" }, { name: "行为数据强提醒" }],
        },
        {
          img: require("@/assets/h5/index/item-xcx.png"),
          url: "/h5/product/xcx",
          title: "房地产全民营销小程序",
          desc: [
            { name: "房源优惠活动实时推送" },
            { name: "多特色房源全民覆盖" },
          ],
        },
        {
          img: require("@/assets/h5/index/item-ydxx.png"),
          url: "/h5/product/ydxx",
          title: "移动行销系统",
          desc: [{ name: "明确客户跟进记录" }, { name: "三种角色行为设置" }],
        },
        {
          img: require("@/assets/h5/index/item-lfdj.png"),
          url: "/h5/product/lfdj",
          title: "来访登记系统",
          desc: [{ name: "客户管理到细节" }, { name: "房源展示及保留" }],
        },
        {
          img: require("@/assets/h5/index/item-fyxcx.png"),
          url: "/h5/product/fyxcx",
          title: "房地产房源展示小程序",
          desc: [{ name: "明确客户跟进记录" }, { name: "三种角色行为设置" }],
        },
        {
          img: require("@/assets/h5/index/item-ywlc.png"),
          url: "/h5/product/ywlc",
          title: "业务流程管理系统",
          desc: [{ name: "客户管理到细节" }, { name: "房源展示及保留" }],
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  .banner {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    .info {
      margin: 0 auto;
      width: 80%;
      .text {
        display: flex;
        justify-content: flex-start;
        margin-top: 4.13rem;
        .left {
          text-align: left;
          font-size: 3rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          font-style: italic;
          color: #cccccc;
        }
        .right {
          margin-left: 0.5rem;
          text-align: left;
          font-size: 3rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          font-style: italic;
          color: #f5b00c;
        }
      }
      .desc {
        margin-top: 2.33rem;
        text-align: left;
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #cccccc;
        line-height: 1.5rem;
      }
      .more {
        margin-top: 2.75rem;
        font-size: 0.67rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        border-top: 2rem solid #03958c;
        border-left: 0px solid transparent;
        border-right: 1.04rem solid transparent;
        height: 2.42rem;
        width: 8.75rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        span {
          text-align: center;
          position: absolute;
          top: -1.46rem;
          left: 1.67rem;
        }
        .jiantou {
          font-size: 1.75rem;
          color: #f5b00c;
          text-align: center;
          position: absolute;
          top: -2.17rem;
          left: 5rem;
        }
      }
    }
  }
  .getmore {
    margin: 0 auto;
    text-align: center;
  }
  .more {
    margin-top: 2.75rem;
    font-size: 0.67rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    border-top: 2rem solid #03958c;
    border-left: 0px solid transparent;
    border-right: 1.04rem solid transparent;
    height: 2.42rem;
    width: 8.75rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    span {
      text-align: center;
      position: absolute;
      top: -1.46rem;
      left: 1.67rem;
    }
    .jiantou {
      font-size: 1.75rem;
      color: #f5b00c;
      text-align: center;
      position: absolute;
      top: -2.17rem;
      left: 5rem;
    }
  }
  .ddky {
    margin: 0 auto;
    text-align: center;

    .ddkyimg {
      margin-top: 2.5rem;
      text-align: center;
      width: 20.04rem;
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    .item {
      width: 8.58rem;
      margin: 0rem 0.5rem 0.5rem 0.5rem;
      align-items: center;
      height: 10.83rem;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      img {
        margin-top: 1.67rem;
        width: 5rem;
      }
      .text {
        margin-top: 1.67rem;
        font-size: 1.25rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
  }

  .cpjz {
    text-align: center;
    .cpjzImg {
      margin-top: 2.5rem;
      text-align: center;
      width: 20.04rem;
    }
    .section {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .listV {
        display: flex;
        justify-content: flex-start;
        margin: 0 auto;
        width: 96%;
        margin-top: 0.5rem;
        padding: 0.5rem;

        background-color: #ffffff;
        img {
          width: 8rem;
          height: 8rem;
          align-items: center;
          background-color: #f5f5f5f5;
        }
        .right {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;
          width: 100%;
          .title {
            text-align: left;
            font-size: 1rem;
            font-family: Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .body {
            display: flex;
            flex-direction: column;
            .list {
              margin-top: 0.5rem;
              display: flex;
              font-size: 1rem;
              justify-content: flex-start;
              align-items: center;
              color: #333333;
              span {
                width: 0.67rem;
                height: 0.25rem;
                background: #03958c;
              }
              .desc {
                margin-left: 0.5rem;
                font-size: 1rem;
                font-family: Source Han Sans SC;
                font-weight: normal;
                color: #666666;
              }
            }
          }
          .click {
            width: 100%;
            text-align: right;
            img {
              background-color: transparent;
              right: 0rem;
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
    }
  }
}
</style>
