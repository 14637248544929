<template>
  <div class="app">
    <Header></Header>
    <div class="bannerh5" :style="bannerStyle">
      <div class="info">
        <div class="en">SERVICE</div>
        <div class="cn">服务支持</div>
      </div>
    </div>
    <div class="tip">
      <img src="@/assets/h5/service/zonghe.png" alt="" />
    </div>
    <div class="zonghe">
      <div class="item" v-for="(item, index) in zongheList" :key="index">
        <div class="left">
          <img :src="item.img" alt="" />
        </div>
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.info }}</div>
        </div>
      </div>
    </div>

    <div class="response">
      <div class="tip">
        <img src="@/assets/h5/service/responsetip.png" alt="" />
      </div>
      <img src="@/assets/h5/service/responseh5.png" alt="" />
    </div>

    <div class="tip">
      <img src="@/assets/h5/service/policyh5.png" alt="" />
    </div>

    <div class="list">
      <div class="section">
        <div class="item" v-for="(item, listIndex) in policyList" :key="listIndex">
          <span :class="item.icon"> </span>
          <div class="name">{{ item.name }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  <!-- h5banner.png -->
</template>

<script>
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/h5banner1.png"),
      bannerStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "16.67rem",
        paddingTop: "2.33rem",
      },
      zongheList: [
        {
          img: require("@/assets/h5/service/more-kefu.png"),
          title: "客服",
          info:
            "专业客服全天24小时在线提供不间断服务，第一时间处理售前售后问题，故障报修第一时间安排专人处理",
        },
        {
          img: require("@/assets/h5/service/more-zixun.png"),
          title: "售前咨询",
          info:
            "了解客户需求及时答疑，安排专人进行远程演示和在线体验服务，帮助项目更好的体现价值",
        },
        {
          img: require("@/assets/h5/service/more-souhou.png"),
          title: "售后",
          info:
            "专业技术团队第一时间帮您解决产品以及使用问题，实施之后有专人培训，保证产品正常使用",
        },
      ],
      policyList: [
        {
          name: "全过程跟进管理",
          icon: "icon font_family icon-genjin",
          text:
            "对用户的服务请求从“受理---派单---预约---过程反馈---结果反馈---回访闭环”进行全过程跟进管理，让您真正用得舒心！",
        },
        {
          name: "极速响应高效处理",
          icon: "icon font_family icon-jisuxiangying",
          text:
            "受理后1小时内有专业的售后工程师响应提供服务，在2个工作日内回复或确认初步解决方案！根据问题严重度，可电话沟通、远程解决或者主动巡检！",
        },
        {
          name: "送装一体服务",
          icon: "icon font_family icon-songzhuangyiti",
          text:
            "我们整合产品配送和安装，为您提供一次性上门送货－安装－调试，让您享受一步到位的贴心体验！",
        },
        {
          name: "应用培训",
          icon: "icon font_family icon-peixun",
          text:
            "专人在现场进行培训指导，协助用户更快的适应环境和了解操作流程，协助企业员工快速上手！",
        },
        {
          name: "故障诊断与处理",
          icon: "icon font_family icon-weixiu",
          text:
            "对于故障诊断处理我们有专业的工程师进行对接：问题诊断与评估；问题处理与反馈；服务过程进行归档；最后进行应用满意度回访！",
        },
        {
          name: "系统增值服务",
          icon: "icon font_family icon-zengzhi",
          text:
            "项目可提供拓展定制化开发、数据安全与管理、应用整合等服务，让产品更懂您，使用更舒心！",
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5f5;
  display: flex;
  flex-direction: column;
  .bannerh5 {
    margin-top: 2.33rem;
  }
  .info {
    margin: 0 auto;
    width: 80%;
    text-align: left;
    margin-left: 3.83rem;
    .en {
      font-size: 1.17rem;
      font-family: Helvetica-BoldOblique;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.42rem;
      opacity: 0.3;
    }
    .cn {
      margin-top: 0.83rem;
      font-size: 2rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.42rem;
    }
  }
  .tip {
    margin: 0 auto;
    text-align: center;
    align-items: center;

    img {
      margin-top: 2.5rem;
      align-items: center;
      width: 80%;
    }
  }
  .zonghe {
    .item {
      background: #ffffff;
      margin: 1rem 1rem 1rem 1rem;
      display: flex;
      justify-content: space-between;
      .left {
        margin: 0 auto;
        align-items: center;
        img {
          margin-top: 0.83rem;
          width: 9.98rem;
          height: 10.46rem;
        }
      }
      .right {
        margin-top: 1rem;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        .title {
          padding-top: 1rem;
          font-size: 1rem;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          line-height: 1.13rem;
        }
        .text {
          padding-top: 1rem;
          font-size: 0.8rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 1.5rem;
        }
      }
    }
  }

  .list {
    padding-top: 3.42rem;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .section {
      margin: 0 auto;
      background-color: #f5f5f5;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 3.5%;
        width: 45%;
        margin-top: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        .icon {
          color: #03958c;
          font-size: 2rem;
          text-align: left;
        }
        .name {
          margin-top: 1rem;
          text-align: left;
          font-size: 1rem;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          line-height: 1rem;
        }
        .text {
          text-align: left;
          margin-top: 0.83rem;
          font-size: 0.95rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 1.67rem;
        }
      }
    }
  }

  .response {
    background-color: #000000;
    .tip {
      margin: 0 auto;
      width: 80%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    img {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
