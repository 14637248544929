<template>
  <div class="app">
    <div class="banner">
      <Carousel :v-model="value" :arrow="arrowValue" loop>
        <CarouselItem>
          <div class="background">
            <img class="bgImg" src="@/assets/BannerHome.png" />
            <div class="title">
              <span class="title1">持续引领</span>
              <span class="title2">地产营销升级</span>
            </div>
            <div class="desc">
              持续引领地产营销升级持续引领地产营销升级持续引领地产营销升级
            </div>
            <!-- <div class="more" @click="go('/service')">
              <span>查看更多</span>
              <span class="icon font_family jiantou"> &#xe605;</span>
            </div> -->
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  // 定义属性
  data() {
    return {
      value: 0,
      arrowValue: "none",
      backgroundImage: {
        backgroundImage: "url(" + this.bgImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 900px;
  .background {
    height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      height: 100%;
      position: absolute; /*设为绝对定位*/
    }
    .title {
      width: inherit;
      text-align: right;
      font-size: 20px;
      position: absolute; /*设为绝对定位*/
      color: #fff;
      padding-top: 270px;
      text-align: center;
      .title1 {
        font-size: 72px;
        font-family: Source Han Sans SC;
        font-weight: 100;
        color: #cccccc;
        line-height: 88px;
      }
      .title2 {
        font-size: 72px;
        font-family: Source Han Sans SC;
        font-weight: 530;
        color: #cccccc;
        line-height: 88px;
      }
    }

    .desc {
      position: relative;
      text-align: center;
      margin: 0 auto;
      height: 200rpx;
      top: 350px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #cccccc;
      line-height: 88px;
    }
    .more {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #ffffff;
      border-top: 48px solid #03958c;
      border-left: 0px solid transparent;
      border-right: 25px solid transparent;
      margin: 0 auto;
      height: 200rpx;
      width: 200px;
      top: 370px;
      position: relative;
      display: flex;
      justify-content: space-between;
      span {
        text-align: center;
        position: absolute;
        top: -35px;
        left: 40px;
      }
      .jiantou {
        font-size: 42px;
        color: #f5b00c;
        text-align: center;
        position: absolute;
        top: -52px;
        left: 120px;
      }
    }
  }
}
</style>
