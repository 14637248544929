<template>
  <div class="app">
    <Header></Header>
    <div class="bannerh5" :style="bannerStyle">
      <div class="info">
        <div class="en">COOPERATION</div>
        <div class="cn">渠道合作</div>
      </div>
    </div>
    <div class="tip">
      <img src="@/assets/h5/cooperation/youshi.png" alt="" />
    </div>
    <div class="tip">
      <img src="@/assets/h5/cooperation/youshi-info.png" alt="" />
    </div>
    <div class="tip">
      <img src="@/assets/h5/cooperation/hezuo-step.png" alt="" />
    </div>
    <div class="tip">
      <img src="@/assets/h5/cooperation/hezuo-step-info.png" alt="" />
    </div>

    <div class="section">
      <div class="tip">
        <img src="@/assets/h5/cooperation/qudao-step.png" alt="" />
      </div>
      <div class="list">
        <div class="section">
          <div class="item" v-for="(item, index) in stepList" :key="index">
            <img :src="item.img" alt="" />
            <div class="name">{{ item.title }}</div>
            <div class="text">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/h5banner1.png"),
      bannerStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "16.67rem",
        paddingTop: "2.33rem",
      },
      stepList: [
        {
          img: require("@/assets/h5/cooperation/step1.png"),
          title: "确定意向",
          desc: "联系渠道经理确认合作意向",
        },
        {
          img: require("@/assets/h5/cooperation/step2.png"),
          title: "签署协议",
          desc: "和渠道经理谈合同事宜，签署合作协议",
        },
        {
          img: require("@/assets/h5/cooperation/step3.png"),
          title: "配置服务",
          desc: "总部配置渠道合作及相关服务和售后",
        },
        {
          img: require("@/assets/h5/cooperation/step4.png"),
          title: "现场实施",
          desc: "快速展开当地销售工作",
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5f5;
  display: flex;
  flex-direction: column;
  .bannerh5 {
    margin-top: 2.33rem;
  }
  .info {
    margin: 0 auto;
    width: 80%;
    text-align: left;
    margin-left: 3.83rem;
    .en {
      font-size: 1.17rem;
      font-family: Helvetica-BoldOblique;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.42rem;
      opacity: 0.3;
    }
    .cn {
      margin-top: 0.83rem;
      font-size: 2rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.42rem;
    }
  }
  .tip {
    margin: 0 auto;
    text-align: center;
    align-items: center;

    img {
      margin-top: 2.5rem;
      align-items: center;
      width: 80%;
    }
  }
  .section {
    background-color: #000000;
    .list {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      .section {
        margin: 0 auto;
        background-color: #000000;
        margin-bottom: 2.58rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-left: 3.5%;
          width: 45%;
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          background-color: #ffffff;
          img {
            width: 100%;
          }
          .icon {
            color: #03958c;
            font-size: 2rem;
            text-align: left;
          }
          .name {
            margin-top: 1.33rem;
            text-align: center;
            font-size: 1.2rem;
            font-family: Source Han Sans SC;
            font-weight: bold;
            color: #333333;
            line-height: 1rem;
          }
          .text {
            margin: 0 auto;
            width: 90%;
            margin-bottom: 0.5rem;
            text-align: center;
            margin-top: 0.83rem;
            font-size: 1rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #999999;
            line-height: 1.67rem;
          }
          .title {
            margin-top: 2.5rem;
            text-align: center;
            font-size: 1.17rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
            line-height: 1.67rem;
          }
        }
      }
    }
  }
}
</style>
