<template>
  <div class="app">
    <div class="zx">
      <img class="zxImg" src="@/assets/home-zxzx.png" />
      <div class="zx-content">
        <div class="zx-item">
          <span>姓名</span>
          <input class="zx-input" v-model="form.username" placeholder="请输入姓名" />
        </div>
        <div class="zx-item">
          <span>手机号</span>
          <input class="zx-input" v-model="form.telphone" placeholder="请输入手机号码" />
        </div>

        <div class="zx-item">
          <span>邮箱</span>
          <input class="zx-input" v-model="form.email" placeholder="请输入邮箱" />
        </div>
        <div class="zx-item">
          <span>产品</span>
          <input class="zx-input" v-model="form.product" placeholder="您想了解哪个产品" />
        </div>
      </div>
      <mu-button class="sub" @click="subForm" color="primary">提交</mu-button>
    </div>

    <div class="footer">
      <div class="fd_content">
        <img class="logo" src="@/assets/logo.png" alt="" />
        <div class="telphone">全国服务热线：028-62094278</div>
        <div class="qrcode">
          <img src="@/assets/qrcode.png" />
          <a class="online_btn" href="tel:028-62094278">来电咨询</a>
        </div>
        <div class="mark">扫码关注</div>
        <div class="conceal" @click="go('/h5/conceal')">隐私政策</div>
        <div class="conceal" @click="go('/h5/userconceal')">服务协议</div>
        <div class="record">蜀ICP备19020384号</div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { addMsg } from "@/untils/index";
import "@/assets/css/iconfont.css";

export default {
  // 定义属性
  data() {
    return {
      form: {
        username: "",
        email: "",
        telphone: "",
        product: "",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    subForm() {
      addMsg(this.form)
        .then((response) => {
          let code = response.code;
          let msg = response.msg;
          if (code != 200) {
            this.$Notice.error({
              title: msg,
            });
          } else {
            this.$Notice.success({
              title: msg,
            });
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  .zx {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    .zxImg {
      width: 80%;
    }
    .zx-content {
      width: 100%;
      margin: 0 auto;
      .zx-item {
        text-align: left;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        span {
          text-align: left;
          font-size: 1rem;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          line-height: 1rem;
        }

        .zx-input {
          margin-top: 0.88rem;
          padding-left: 1.04rem;
          font-size: 1rem;
          height: 3.5rem;
          border-style: none;
          font-size: 1rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 1rem;
        }
        .zx-input:focus {
          outline: none;
          border: 0.04rem solid #03958c;
        }
      }
    }

    .sub {
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 100px;
      font-size: 0.67rem;
      font-family: Source Han Sans SC;
      font-weight: 400;
      background-color: #03958c;
      color: #ffffff;
      text-align: center;
      width: 90%;
      height: 3.67rem;
      font-size: 1.25rem;
    }
  }

  .footer {
    background-color: #000000;
    .fd_content {
      margin: 0 auto;
      text-align: center;
      .logo {
        margin-top: 1.5rem;
        height: 2rem;
      }
      .telphone {
        margin-top: 1rem;
        margin-bottom: 1rem;

        text-align: center;
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #f5b00c;
      }
      .qrcode {
        display: flex;
        justify-content: space-between;
        img {
          margin-top: 1.5rem;
          margin: 0 auto;
          text-align: center;
          width: 14rem;
          height: 14rem;
        }
        .online_btn {
          display: inline-block;
          width: 123px;
          height: 37px;
          border-radius: 50px;
          background: #fc0;
          font-size: 1rem;
          border: 1px solid #fc0;
          position: fixed;
          z-index: 100000;
          right: 20px;
          bottom: 20px;
          line-height: 37px;
          color: #000;
          box-sizing: border-box;
          text-indent: 40px;
        }

        .online_btn::after {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 15px;
          top: 8.5px;
          display: block;
          background: url("../../assets/tel_icon.png") no-repeat;
          background-size: cover;
          content: "";
          -webkit-animation: rotateIn 2s ease infinite;
        }
      }
      .mark {
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #cccccc;
      }
      .conceal {
        margin-top: 0.9rem;
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #cccccc;
      }
      .record {
        margin-top: 0.9rem;
        font-size: 1rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #cccccc;
      }
    }
  }
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}
</style>
