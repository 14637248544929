<template>
  <div class="app">
    <Header class="header" :style="headerStyle">
      <Menu mode="horizontal" class="menu" :active-name="activeName">
        <div class="layout-logo">
          <router-link to="/">
            <img class="logo" :src="logo" />
          </router-link>
        </div>
        <div class="layout-nav">
          <MenuItem class="navItem" name="1" to="/">
            <span class="nav-hd" :style="navHdStyle">首页</span
            ><span class="nav-fd">Home</span>
          </MenuItem>

          <MenuItem class="navItem" name="2">
            <span class="nav-hd" :style="navHdStyle" @mouseenter="enters()">产品</span
            ><span class="nav-fd" @mouseenter="enters()">PRODUCT</span>
          </MenuItem>

          <!-- <Submenu class="navItem" name="2">
            <template slot="title" style="width: 200px; height: 200px">
              <span class="nav-hd" :style="navHdStyle">产品</span>
              <span class="nav-fd">PRODUCT</span>
            </template>
            <MenuGroup style="width: 100%; background-color: red">
              <MenuItem
                v-for="(item, index) in product"
                :key="index"
                :to="item.url"
                :name="item.number"
                >{{ item.name }}</MenuItem
              >
            </MenuGroup>
          </Submenu> -->

          <MenuItem class="navItem" name="3" to="/about"
            ><span class="nav-hd" :style="navHdStyle">关于我们</span>
            <span class="nav-fd">ABOUT</span>
          </MenuItem>
          <MenuItem class="navItem" name="4" to="/cooperation"
            ><span class="nav-hd" :style="navHdStyle">渠道合作</span>
            <span class="nav-fd">COOPERATION</span></MenuItem
          >
          <MenuItem class="navItem" name="5" to="/service"
            ><span class="nav-hd" :style="navHdStyle">服务支持</span>
            <span class="nav-fd">SERVICE</span></MenuItem
          >
          <MenuItem class="navItem" name="6" to="/download"
            ><span class="nav-hd" :style="navHdStyle">App下载</span>
            <span class="nav-fd">DOWNLOAD</span>
          </MenuItem>
          <div class="phone">
            <img class="phoneImg" src="@/assets/phone.png" />
          </div>
        </div>
      </Menu>
    </Header>
    <div class="drown" v-show="productshow" @mouseleave="leaver()">
      <div class="content">
        <Row :gutter="16">
          <Col span="6" v-for="(item, index) in product" :key="index">
            <div class="item" @click="go(item.url)">{{ item.name }}</div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  props: ["data"],

  // 定义属性
  data() {
    return {
      activeName: "",
      logo: require("@/assets/logo.png"),
      headerStyle: {
        backgroundColor: "",
        height: "90px",
        lineHeight: "90px",
      },
      productshow: false,
      navHdStyle: {
        color: "#ffffff",
      },
      product: [
        {
          name: "房地产CRM营销管理系统",
          number: "2-1",
          url: "/product/crm",
        },
        {
          name: "房地产全民营销小程序",
          number: "2-2",
          url: "/product/xcx",
        },
        {
          name: "房地产商业智能系统",
          number: "2-3",
          url: "/product/syzn",
        },
        {
          name: "移动行销系统",
          url: "/product/ydxx",
          number: "2-4",
        },
        {
          name: "业务流程管理系统",
          url: "/product/ywlc",
          number: "2-5",
        },
        {
          name: "智慧工地管理系统",
          url: "/product/zhgd",
          number: "2-6",
        },
        {
          name: "来访登记系统",
          url: "/product/lfdj",
          number: "2-7",
        },
        {
          name: "房源展示系统",
          url: "/product/fyxcx",
          number: "2-8",
        },
        {
          name: "在线开盘系统",
          url: "/product/zxkp",
          number: "2-9",
        },

        {
          name: "房地产成本管理系统",
          url: "/product/cbgl",
          number: "2-10",
        },
        {
          name: "拆迁管理系统",
          url: "/product/cqgl",
          number: "2-11",
        },
        {
          name: "在线开盘系统",
          url: "/product/zxkp",
          number: "2-12",
        },
        {
          name: "客户电话回访管理",
          url: "/product/dhhf",
          number: "2-13",
        },
        {
          name: "房地产前策系统",
          url: "/product/qcxt",
          number: "2-14",
        },
        {
          name: "渠道管理系统",
          url: "/product/qdgl",
          number: "2-15",
        },
        {
          name: "物业管理系统",
          url: "/product/wygl",
          number: "2-16",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
    enters() {
      this.productshow = true;
    },
    leaver() {
      this.productshow = false;
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (scrollTop > 200) {
        this.headerStyle.backgroundColor = "#ffffff";
        this.logo = require("@/assets/logo_green.png");
        this.phoneImg = require("@/assets/phone_black.png");
        this.navHdStyle.color = "#666";
      }
      if (scrollTop < 200) {
        this.headerStyle.backgroundColor = "transparent";
        this.logo = require("@/assets/logo.png");
        this.phoneImg = require("@/assets/phone.png");
        this.navHdStyle.color = "#ffffff";
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (
      /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/h5";
    }
    this.activeName = this.$route.meta.activeName;
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {
    window.removeEventListener("scroll", this.scrollHander);
  }, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  padding-left: 50px;
  text-align: center;
  background-color: transparent;
  .menu {
    padding-top: 16px;
    margin: 0 auto;
    display: flex;
    background: transparent;
    justify-content: space-between;
    .layout-logo {
      width: 100px;
      border-radius: 3px;
      float: left;
      position: relative;
      display: table-cell;
      vertical-align: middle;
    }
    .layout-nav {
      margin: 0 auto;
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      .navItem:hover span {
        color: #03958c !important;
      }

      .navItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 60px;
        height: 60px;
        .nav-hd {
          height: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
        }
        .nav-fd {
          margin-top: 10px;
          font-family: Helvetica;
          font-weight: 400;
          color: #cccccc;
          line-height: 34px;
          height: 20px;
        }
        span {
          height: 20px;
        }
      }
    }
  }
}
.drown {
  height: 315px;
  width: 100%;
  z-index: 99;
  top: 90px;
  position: fixed;
  background-color: #ffffff;
  .content {
    width: 60%;
    margin: 0 auto;
    text-align: left;
    padding-top: 50px;
    .item {
      height: 38px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      line-height: 38px;
    }
    .item:hover {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #03958c;
      line-height: 38px;
    }
  }
}

.ivu-menu-submenu /deep/ .ivu-menu-submenu-title {
  display: flex !important;
  flex-direction: column !important;
}
.ivu-menu-submenu /deep/ .ivu-icon {
  display: none;
}

.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
  color: #03958c;
  border-bottom: 2px solid transparent;
}
.ivu-menu-horizontal.ivu-menu-light:after {
  background: transparent !important;
}

.ivu-menu-item-active span {
  // display: none;
  color: #03958c !important;
}
</style>
