<template>
  <div class="body">
    <Header></Header>
    <div class="list">
      <div class="section">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="go(item.url)"
        >
          <img :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";

export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性

  data() {
    return {
      list: [
        {
          img: require("@/assets/h5/index/item-crm.png"),
          url: "/h5/product/crm",
          title: "房地产CRM营销管理系统",
        },
        {
          img: require("@/assets/h5/index/item-xcx.png"),
          url: "/h5/product/xcx",
          title: "房地产全民营销小程序",
        },
        {
          img: require("@/assets/h5/index/syzn.png"),
          url: "/h5/product/syzn",
          title: "房地产商业智能",
        },
        {
          img: require("@/assets/h5/index/item-ydxx.png"),
          url: "/h5/product/ydxx",
          title: "移动行销系统",
        },
        {
          img: require("@/assets/h5/index/item-ywlc.png"),
          url: "/h5/product/ywlc",
          title: "业务流程管理系统",
        },

        {
          img: require("@/assets/h5/index/zhgd.png"),
          url: "/h5/product/zhgd",
          title: "智慧工地管理系统",
        },

        {
          img: require("@/assets/h5/index/zxkp.png"),
          url: "/h5/product/zxkp",
          title: "在线开盘系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-招标.png"),
          url: "/h5/product/zbcg",
          title: "招标采购合同管理系统",
        },
        {
          img: require("@/assets/h5/index/item-lfdj.png"),
          url: "/h5/product/lfdj",
          title: "来访登记系统",
        },

        {
          img: require("@/assets/h5/index/index-pro-成本管理.png"),
          url: "/h5/product/cbgl",
          title: "房地产成本管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-拆迁管理.png"),
          url: "/h5/product/cqgl",
          title: "拆迁管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-电话回访.png"),
          url: "/h5/product/dhhf",

          title: "客户电话回访管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-前策.png"),
          url: "/h5/product/qcxt",

          title: "房地产前策系统",
        },
        {
          img: require("@/assets/h5/index/item-fyxcx.png"),
          url: "/h5/product/fyxcx",
          title: "房地产房源展示小程序",
        },

        {
          img: require("@/assets/h5/index/index-pro-渠道管理.png"),
          url: "/h5/product/qdgl",
          title: "渠道管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-物业管理.png"),
          url: "/h5/product/wygl",
          title: "物业管理系统",
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='scss' scoped>
.body {
  background-color: #f5f5f5;
  .list {
    margin: 0 auto;
    padding-top: 56px;
    text-align: center;
    width: 100%;
    .section {
      margin: 0 auto;
      background-color: #f5f5f5;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-left: 3.5%;
        width: 45%;
        margin-top: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        img {
          margin: 0 auto;
          width: 8.33rem;
          height: 8.33rem;
          align-items: center;
          text-align: center;
          background-color: #ffffff;
        }
        .title {
          margin-top: 1.67rem;
          text-align: center;
          font-size: 1.17rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
          line-height: 1.67rem;
        }
      }
    }
  }
}
</style>