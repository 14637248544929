<template>
  <div class="app">
    <mu-appbar style="width: 100%; position: fixed" color="#000000">
      <div slot="left"><img class="logo" :src="logo" @click="go('/h5')" /></div>
      <mu-menu slot="right">
        <mu-button flat icon @click="open = !open">
          <img class="menu" :src="menu" />
        </mu-button>
      </mu-menu>
    </mu-appbar>

    <mu-drawer
      class="drawer"
      :open.sync="open"
      :docked="docked"
      :right="position === 'right'"
    >
      <mu-list>
        <mu-list-item @click="open = false" button>
          <div class="close">
            <img class="closeImg" :src="close" />
          </div>
        </mu-list-item>

        <mu-list toggle-nested>
          <mu-list-item
            button
            :ripple="false"
            nested
            :open="openIndex === index"
            @toggle-nested="openIndex = arguments[0] ? index : ''"
            v-for="(item, index) in drawerList"
            @click="go(item.url)"
          >
            <mu-list-item-title :class="item.children ? 'green' : ''">{{
              item.name
            }}</mu-list-item-title>

            <mu-list-item
              v-for="(childItem, childIndex) in item.children"
              button
              :ripple="false"
              slot="nested"
              @click="go(childItem.url)"
            >
              <mu-list-item-title>{{ childItem.name }}</mu-list-item-title>
            </mu-list-item>
          </mu-list-item>
        </mu-list>
      </mu-list>
    </mu-drawer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  // 定义属性
  data() {
    return {
      menu: require("@/assets/h5/menu.png"),
      close: require("@/assets/h5/close.png"),
      logo: require("@/assets/h5/logoh5.png"),
      docked: true,
      open: false,
      openIndex: 0,
      position: "right",
      panel: "",
      drawerList: [
        {
          name: "首页",
          url: "/h5",
        },
        {
          name: "产品",
          children: [
            {
              name: "房地产CRM营销管理系统",
              url: "/h5/product/crm",
            },
            {
              name: "房地产全民营销小程序",
              url: "/h5/product/xcx",
            },
            {
              name: "房地产商业智能系统",
              url: "/h5/product/syzn",
            },
            {
              name: "移动行销系统",
              url: "/h5/product/ydxx",
            },
            {
              name: "业务流程管理系统",
              url: "/h5/product/ywlc",
            },
            {
              name: "智慧工地管理系统",
              url: "/h5/product/zhgd",
            },
            {
              name: "来访登记系统",
              url: "/h5/product/lfdj",
            },
            {
              name: "房源展示系统",
              url: "/h5/product/fyxcx",
            },
            {
              name: "在线开盘系统",
              url: "/h5/product/zxkp",
            },

            {
              name: "房地产成本管理系统",
              url: "/h5/product/cbgl",
            },
            {
              name: "拆迁管理系统",
              url: "/h5/product/cqgl",
            },
            {
              name: "在线开盘系统",
              url: "/h5/product/zxkp",
            },
            {
              name: "客户电话回访管理",
              url: "/h5/product/dhhf",
            },
            {
              name: "房地产前策系统",
              url: "/h5/product/qcxt",
            },
            {
              name: "渠道管理系统",
              url: "/h5/product/qdgl",
            },
            {
              name: "物业管理系统",
              url: "/h5/product/wygl",
            },
          ],
        },
        {
          name: "关于我们",
          url: "/h5/about",
        },
        {
          name: "渠道合作",
          url: "/h5/cooperation",
        },
        {
          name: "服务支持",
          url: "/h5/service",
        },
        {
          name: "APP下载",
          url: "/h5/app",
        },
      ],
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    toggle(panel) {
      this.panel = panel === this.panel ? "" : panel;
    },
    go(url) {
      this.$router.push({ path: url });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  .logo {
    width: 70%;
    height: 100%;
    padding-left: 8%;
  }
  .menu {
    height: 100%;
  }
  .close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .closeImg {
      width: 2rem;
      height: 2rem;
    }
  }

  .drawer {
    background-color: #000000;
  }

  .expansion {
    background-color: #000000;
    color: #ffffff;
    border-bottom: 1px solid #333333;
  }
}

.mu-item-title {
  color: #ffffff !important;
}
.green {
  color: #03958c !important;
}

/deep/ .mu-button-wrapper {
  background: #000000;
  color: #ffffff;
}

/deep/.black {
  color: #ffffff !important;
  svg {
    color: #000000 !important;
  }
}

/deep/ .mu-expansion-panel__expand .mu-expansion-panel-header {
  color: #03958c;
}
.demo-list-wrap {
  width: 100%;
  max-width: 360px;
  overflow: hidden;
}
</style>
