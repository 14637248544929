<template>
  <div class="app">
    <Header></Header>
    <div class="bannerh5" :style="bannerStyle">
      <div class="info">
        <div class="en">About</div>
        <div class="cn">关于我们</div>
      </div>
    </div>
    <div class="tip">
      <img src="@/assets/h5/about/about.png" alt="" />
    </div>
    <div class="desc">
      一群认真专注的工程师和一个个伟大的产品构想。
      成都扬帆启初科技有限公司成立于2018年，成员来自于上市公司核心团队，致力于金融，房产IT服务及智慧物联网产品的设计研发，我们秉持着简明、坚定的信念：提供创新与便捷的产品，并佐以贴心的客户服务，提供高质的产品及服务，提升客户的便利性。这是我们的理念，为此，我们不断向前迈进！
    </div>
    <div class="house">
      <img src="@/assets/h5/about/house.png" alt="" />
    </div>
    <div class="tip">
      <img src="@/assets/h5/about/dream.png" alt="" />
    </div>
    <div class="desc">
      客户的满意是启初设计产品的宗旨，我们要让您相信启初的产品物超所值-高阶的产品，高质的服务！
    </div>
    <div class="tip">
      <img src="@/assets/h5/about/dream-info.png" alt="" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/h5banner1.png"),
      bannerStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
        height: "16.67rem",
        paddingTop: "2.33rem",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5f5;
  display: flex;
  flex-direction: column;
  .bannerh5 {
    margin-top: 2.33rem;
  }
  .info {
    margin: 0 auto;
    width: 80%;
    text-align: left;
    margin-left: 3.83rem;
    .en {
      font-size: 1.17rem;
      font-family: Helvetica-BoldOblique;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.42rem;
      opacity: 0.3;
    }
    .cn {
      margin-top: 0.83rem;
      font-size: 2rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.42rem;
    }
  }
  .tip {
    margin: 0 auto;
    text-align: center;
    align-items: center;

    img {
      align-items: center;
      width: 80%;
    }
  }
  .desc {
    margin: 0 auto;
    width: 90%;
    // font-size: 1rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #666666;
    line-height: 1.92rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .house {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
