<template>
  <div class="app">
    <div class="header">
      <router-link to="/">
        <img class="logo" src="@/assets/logo.png"
      /></router-link>
      <div class="nav">
        <router-link to="/"> <div class="item" to="/">首页</div></router-link>
        <router-link to="/download"
          ><div class="item download">APP下载</div></router-link
        >
      </div>
    </div>

    <div class="info">
      <div class="left">
        <img src="@/assets/download_left.png" />
      </div>
      <div class="right">
        <div class="detail">
          <div class="title">APP下载</div>
          <div class="desc">扬帆起初房地产营销App,书写房地产营销新篇章</div>
        </div>
        <div class="donwload">
          <div class="downitem">
            <div class="button" @click="go('down/yfqc.apk')">
              <span class="icon font_family"> &#xe690; </span>
              <div class="font">Android下载</div>
            </div>

            <!-- <img src="@/assets/androidDown.png" alt="" /> -->
          </div>
          <div class="downitem">
            <div
              class="button"
              @click="
                go(
                  'http://mp.weixin.qq.com/mp/redirect?url=https%3A%2F%2Fapps.apple.com%2Fcn%2Fapp%2Fid1576329182'
                )
              "
            >
              <span class="icon font_family">&#xe601;</span>
              <div class="font">iPhone下载</div>
            </div>
            <!-- <img src="@/assets/iosDown.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/iconfont.css";

export default {
  components: {},
  data() {
    return {
      bodyBgImage: "url(" + require("@/assets/downloadBg.png") + ")",
      env:"",
    };
  },
  methods: {
    go(url) {
      if(this.env=="wechat"){
        alert("wechat")
        
      }else{
        window.location.href = url;
      }
    },
    // 添加body图片
    setBodyBackGround() {},
    // 清除背景图
    clearBodyBackGround() {
      document.body.style.backgroundImage = "";
    },

    isAlipayOrWechat() {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        this.env = "alipay";
    
      } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        this.env = "wechat";
        
      } else {
        return "";
      }
    },
  },
  created() {
    // 进来的时候调用添加
    if (
      /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(
        navigator.userAgent
      )
    ) {
      window.location.href = "/h5/app";
    }

    this.isAlipayOrWechat();
    this.setBodyBackGround();
  },
  beforeDestroy() {
    // 离开页面的时候清除
    this.clearBodyBackGround();
  },
};
</script>

<style scoped></style>
<style lang="scss" scoped>
.app {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/downloadBg.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 100%;
  position: fixed;
  .header {
    position: fixed;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: transparent;
    align-content: center;
    .logo {
      width: 150px;
      height: 50px;
    }
    .nav {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        padding-left: 40px;
      }
      .download {
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #f5b00c;
        line-height: 34px;
      }
    }
  }

  .info {
    margin: 0 auto;
    padding-top: 66px;
    display: flex;
    justify-content: center;
    .left {
      img {
        width: 80%;
      }
    }
    .right {
      padding-top: 110px;
      .title {
        font-size: 48px;
        font-family: Source Han Sans SC;
        color: #ffffff;
        line-height: 48px;
      }
      .desc {
        padding-top: 44px;
        height: 100%;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
      }
      .donwload {
        margin-top: 26px;
        display: flex;
        justify-content: flex-start;
        .downitem {
          display: flex;
          flex-direction: column;
          .button {
            margin-right: 20px;
            text-align: center;
            background: linear-gradient(
              0deg,
              rgba(248, 244, 178, 0.73),
              rgba(255, 255, 255, 0.73)
            );
            box-shadow: 0px 46px 68px 0px rgba(3, 149, 140, 0.23),
              0px 4px 6px 0px #ffffff,
              -3px -3px 7px 0px rgba(52, 170, 163, 0.52);
            border-radius: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 30px;
              color: #03958c;
              padding-left: 20px;
            }
            .font {
              font-size: 13px;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #2f4b49;
              padding-left: 20px;
              padding-right: 20px;
              line-height: 34px;
            }
          }
          img {
            width: 290px;
          }
        }
      }
    }
  }
}
</style>
