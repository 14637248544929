<template>
  <div class="app">
    <Header></Header>
    <div class="bannerh5" :style="bannerStyle">
      <div class="info">
        <span class="green">业务流程管理系统</span>
      </div>
      <img class="currentImg" :src="currentImg" alt="" />
    </div>
    <div class="tip">
      <img src="@/assets/h5/product/crm/desc.png" alt="" />
    </div>

    <div class="bannerBg">
      <swiper class="swiperBanner" :options="swiperOptions">
        <swiper-slide
          class="slideList"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <div class="zz">{{ item.name }}</div>
          <img class="bgImg" :src="item.img" mode="" />
          <div class="mask active"></div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="swiper"></div>
    <div class="tip">
      <img src="@/assets/h5/product/crm/youshi.png" alt="" />
    </div>

    <div class="list">
      <div class="section">
        <div class="item" v-for="(item, listIndex) in list" :key="listIndex">
          <div class="number">
            {{ listIndex + 1 }}
          </div>
          <div class="img">
            <img :src="item.img" alt="" />
          </div>
          <div class="info">
            <div class="name">{{ item.title }}</div>
            <div class="text">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";
import Footer from "@/components/H5/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/productBg.png"),
      bannerStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%",
        backgroundSize: "contain",
      },
      currentImg: require("@/assets/h5/index/item-ywlc.png"),

      bannerList: [
        {
          img: require("@/assets/crm-swiper-1.png"),
          name: "房企业务流程麻烦",
        },
        {
          img: require("@/assets/crm-swiper-2.png"),
          name: "过程监控难",
        },
        {
          img: require("@/assets/crm-swiper-3.png"),
          name: "管理效率低",
        },
      ],
      list: [
        {
          img: require("@/assets/h5/product/业务-1.png"),
          title: "系统编辑",
          desc: "用户可以在该系统内编辑房地产销售运营业务流程信息",
        },
        {
          img: require("@/assets/h5/product/业务-2.png"),
          title: "插件丰富",
          desc: "系统拥有各类高效的工具插件",
        },
        {
          img: require("@/assets/h5/product/业务-3.png"),
          title: "提升工作效率",
          desc: "其强大的后台处理能力能够实时对房地产销售运营业务流程信息进行管控",
        },
      ],
      swiperOptions: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        spaceBetween: 0,
        slidesPerView: 2,
        centeredSlides: true,
        slideToClickedSlide: true,
        grabCursor: true,
        loop: true,
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang='scss' scoped>
.swiper-container {
  width: 100%;
  height: 12.5rem;
  margin: 2.08rem auto;
}
.swiper-slide {
  background: #f1f1f1;
  color: #000;
  line-height: 12.5rem;
}

.app {
  background-color: #f5f5f5;
  .bannerh5 {
    align-items: center;
    text-align: center;
    .info {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      font-size: 1.5rem;
      padding-top: 5.33rem;
      .green {
        color: #03958c;
      }
    }
    .currentImg {
      height: 9.08rem;
      margin: 0 auto;
      text-align: center;
    }
  }
  .tip {
    margin: 0 auto;
    text-align: center;
    img {
      align-items: center;
      width: 80%;
    }
  }

  .bannerBg {
    width: 100%;
    background-size: cover;
    margin-top: 1rem;
    right: 0;
    display: flex;
    align-items: center;
    .slideList {
      width: 8rem;
      height: 8rem;
      .zz {
        font-size: 1rem;
        color: #fff;
        position: absolute;
        z-index: 2;
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 8rem;
        height: 8rem;
      }
      .bgImg {
        width: 8rem;
        height: 8rem;
      }
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 8rem;
        height: 8rem;
      }
    }
    .swiper-slide-active {
      width: 10rem;
      height: 10rem;
      .zz,
      .bgImg,
      .mask {
        width: 10rem;
        height: 10rem;
      }
      .mask {
        background: rgba(3, 148, 138, 0.8);
      }
    }
    .swiperBanner {
      .swiper-wrapper {
        display: flex !important;
        align-items: center !important;
      }
    }
  }

  .list {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .section {
      margin: 0 auto;
      background-color: #f5f5f5;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        margin: 0 auto;
        width: 95%;
        margin-top: 0.5rem;
        padding: 0.5rem;
        display: flex;
        justify-content: flex-start;
        background-color: #ffffff;
        .number {
          width: 0.75rem;
          height: 1.42rem;
          font-size: 2rem;
          font-family: Helvetica;
          font-weight: bold;
          font-style: italic;
          color: #f5b00c;
          line-height: 1.67rem;
        }
        .img {
          margin-left: 1rem;
          align-items: center;
          img {
            width: 10.42rem;
            height: 8.33rem;
          }
        }
        .info {
          margin-left: 1rem;
          .name {
            text-align: left;
            font-size: 1.25rem;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            line-height: 1.5rem;
          }
          .text {
            text-align: left;
            font-size: 1.17rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #666666;
            line-height: 1.67rem;
          }
        }
      }
    }
  }
}
</style>