<template>
  <div class="app">
    <Header></Header>
    <div class="background" :style="AppStyle">
      <div class="info">
        <div class="title">APP下载</div>
        <div class="desc">
          楼东东案场app是一款为房地产打造的CRM软件，方便用户及时管理业务员提交的客户，一键即可申请想要管理的项目，方便结算账务！
        </div>
        <div class="down">
          <div
            class="downItem"
            @click="go('http://www.yangfanqichu.com/down/yfqc.apk')"
          >
            <span class="font_family icon-anzhuo"></span>
            <div class="name">Android下载</div>
          </div>
          <div
            class="downItem"
            @click="goIOS('https://apps.apple.com/cn/app/id1576329182')"
          >
            <span class="font_family icon-ios"></span>
            <div class="name">iPhone下载</div>
          </div>
        </div>
        <img class="telphone" src="@/assets/h5/app/telphone.png" alt="" />
      </div>
    </div>

    <div v-show="showTip" @click="closeShade()"  id="weixinTip" ref="weixin" class="wxtip">
      <p class="wxtip-txt">
        在微信中无法下载APP
        <br />
        1. 请点击右上角按钮
        <br />
        2. 选择【在默认浏览器中打开】
      </p>
    </div>
  </div>
</template>

<script>
import "@/assets/css/iconfont.css";
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/H5/Header";

export default {
  components: {
    Header,
  },
  // 定义属性
  data() {
    return {
      bannerImg: require("@/assets/h5/app/background.png"),
      AppStyle: {
        backgroundImage: "url(" + this.bannerImg + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "100vh",
        width: "100vw",
      },
      env: "",
      showTip: false,
      isAndroid: "",
      isMac: "",
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(url) {
      window.location.href = url;
    },
    goIOS(url) {
      const { userAgent } = navigator;
      const isWeixin = !!/MicroMessenger/i.test(userAgent);
      this.isAndroid =
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
      this.isMac = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isWeixin) {
        //如果是微信环境，就弹出遮罩层
        this.showTip = true;
      } else {
        window.location.href = url;
      }
    },
    isAlipayOrWechat() {
      var userAgent = window.navigator.userAgent.toLowerCase();
      if (userAgent.match(/Alipay/i) == "alipay") {
        this.env = "alipay";
      } else if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
        this.env = "wechat";
      } else {
        return "";
      }
    },
    closeShade(){
      this.showTip = false;
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.isAlipayOrWechat();
    this.AppStyle.backgroundImage = "url(" + this.bannerImg + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  .background {
    .info {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .title {
        margin-top: 5.46rem;
        font-size: 2rem;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.42rem;
      }
      .desc {
        margin-top: 3.33rem;
        margin-top: 1.88rem;
        font-size: 1.17rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 1.67rem;
      }
      .down {
        margin-top: 3.33rem;
        display: flex;
        justify-content: space-between;
        .downItem {
          font-size: 1.08rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #2f4b49;
          width: 10.08rem;
          height: 3.79rem;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.73),
            rgba(248, 244, 178, 0.73)
          );
          box-shadow: 0px 1.92rem 2.83rem 0rem rgba(3, 149, 140, 0.23),
            0rem 0.17rem 0.25rem 0rem #ffffff,
            -0.13rem -3px 0.29rem 0rem rgba(52, 170, 163, 0.52);
          border-radius: 0.67rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            margin-left: 0.3rem;
            color: #03958c;
            font-size: 2.5rem;
          }
          div {
            padding-left: 0.27rem;
            font-size: 1.08rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #2f4b49;
            line-height: 1.42rem;
          }
        }
      }
      .telphone {
        padding-top: 2.29rem;
        width: 90%;
        height: 100%;
        margin: 0 auto;
      }
    }
  }

  .wxtip {
    background: rgba(0, 0, 0, 0.9);
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    // display: none;
    img {
      width: 100%;
    }
  }

  .wxtip-txt {
    margin-top: 107px;
    color: #fff;
    font-size: 20px;
    line-height: 1.8;
  }
}
</style>
