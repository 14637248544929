<template>
  <div class="app">
    <Header :active="activeName"></Header>
    <Swiper></Swiper>
    <div class="body">
      <div class="ddky">
        <div
          class="left"
          v-on:mouseover="changeActive(1)"
          v-on:mouseout="addActive(1)"
        >
          <img class="home_ddky_1" src="@/assets/home_ddky_1.png" />
          <div :class="ddky1">
            <span>PC端</span>
            <span class="icon font_family jiantou"> &#xe605;</span>
          </div>
        </div>
        <div class="right">
          <div class="right-hd">
            <div
              class="home_ddky_2"
              v-on:mouseover="changeActive(1)"
              v-on:mouseout="addActive(2)"
            >
              <img src="@/assets/home_ddky_2.png" />
            </div>
          </div>
          <div class="right-fd">
            <div
              class="home_ddky_4"
              v-on:mouseover="changeActive(4)"
              v-on:mouseout="addActive(4)"
            >
              <img src="@/assets/home_ddky_4.png" />
              <div :class="ddky4">
                <span>移动端</span>
                <span class="icon font_family jiantou"> &#xe605;</span>
              </div>
            </div>

            <div
              class="home_ddky_3"
              v-on:mouseover="changeActive(3)"
              v-on:mouseout="addActive(3)"
            >
              <img src="@/assets/home_ddky_3.png" />
              <div :class="ddky3">
                <span>小程序</span>
                <span class="icon font_family jiantou"> &#xe605;</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cpjz">
        <div class="cpjzimg">
          <img src="@/assets/home_cpjz.png" />
        </div>
        <div class="crm" v-show="crm">
          <div class="crm-left">
            <img :class="crmImg1" src="@/assets/home_crm_1.png" />
            <img :class="crmImg2" src="@/assets/home_crm_2.png" />
          </div>
          <div class="crm-right">
            <div :class="crmTitle">房地产CRM营销管理系统</div>
            <div :class="crmRight">
              <span class="span-line"></span>大数据营销管理
            </div>
            <div :class="crmRight">
              <span class="span-line"></span>行为数据强提醒
            </div>
            <div :class="crmRight">
              <span class="span-line"></span>规范业务流程
            </div>
            <div :class="crmMore" @click="go('/product/crm')">了解详情 >></div>
          </div>
        </div>
      </div>

      <div class="dao3"></div>
      <div style="background: #ffffff">
        <div class="section" v-show="xcx">
          <div class="cpjz-white">
            <div class="cpjz-left-content">
              <div :class="xcxTitle">房地产全民营销小程序</div>
              <div :class="xcxLeft">
                <span class="span-line"></span>
                <div>房源优惠活动实时推送</div>
              </div>
              <div :class="xcxLeft">
                <span class="span-line"></span>
                <div>多特色房源全民覆盖</div>
              </div>
              <div :class="xcxLeft">
                <span class="span-line"></span>
                <div>从源头把控房源品质</div>
              </div>
              <div :class="xcxLeft">
                <span class="span-line"></span>
                <div>专业顾问帮约看房，找房有保障</div>
              </div>
              <div :class="xcxMore" @click="go('/product/xcx')">
                了解详情 >>
              </div>
            </div>
            <div class="cpjz-right">
              <img :class="xcxImg1" src="@/assets/home_xcx_1.png" />
              <img :class="xcxImg2" src="@/assets/home_xcx_2.png" />
            </div>
          </div>
        </div>
      </div>

      <div class="zheng3"></div>
      <div class="cpjz" v-show="xxxt">
        <div class="crm" style="margin-top: 0px">
          <div class="crm-left">
            <img :class="xxxtImg1" src="@/assets/home_xxxt_1.png" />
            <img :class="xxxtImg2" src="@/assets/home_xxxt_2.png" />
          </div>
          <div class="crm-right">
            <div :class="xxxtTitle">移动行销系统</div>
            <div :class="xxxtRight">
              <span class="span-line"></span>明确客户跟进记录
            </div>
            <div :class="xxxtRight">
              <span class="span-line"></span>三种角色行为设置
            </div>
            <div :class="xxxtRight">
              <span class="span-line"></span>手机跟客，工作提效成交翻倍
            </div>
            <div :class="xxxtRight">
              <span class="span-line"></span>案场数据尽在掌握
            </div>
            <div :class="xxxtRight">
              <span class="span-line"></span>实时查看客户动态，内外合力促转化
            </div>

            <div :class="xxxtMore" @click="go('/product/ydxx')">
              了解详情 >>
            </div>
          </div>
        </div>
      </div>

      <div class="dao3"></div>
      <div style="background: #ffffff">
        <div class="section" style="background: #ffffff" v-show="lfdj">
          <div class="cpjz-white">
            <div class="cpjz-left-content">
              <div :class="lfdjTitle">来访登记系统</div>
              <div :class="lfdjLeft">
                <span class="span-line"></span>
                <div>客户管理到细节</div>
              </div>
              <div :class="lfdjLeft">
                <span class="span-line"></span>
                <div>房源展示及保留</div>
              </div>
              <div :class="lfdjLeft">
                <span class="span-line"></span>
                <div>业绩管理</div>
              </div>
              <div :class="lfdjLeft">
                <span class="span-line"></span>
                <div>报备单管理</div>
              </div>
              <div :class="lfdjMore" @click="go('/product/lfdj')">
                了解详情 >>
              </div>
            </div>
            <div class="cpjz-right">
              <img :class="lfdjImg1" src="@/assets/home_djxt_1.png" />
              <img :class="lfdjImg2" src="@/assets/home_djxt_2.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="zheng3"></div>
      <div class="getmore">
        <div class="more" @click="changeLayer()">
          <span>查看全部</span>
          <span class="icon font_family jiantou"> &#xe605;</span>
        </div>
      </div>
    </div>

    <div class="maskLayer" v-show="layer">
      <div class="layer-content">
        <div class="close" @click="closeLayer()">
          <img src="@/assets/close.png" alt="" />
        </div>
        <div class="productList">
          <div class="list" v-for="(item, index) in list" :key="index">
            <div class="item" @click="go(item.url)">
              <img :src="item.img" alt="" />
              <div class="desc">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import "@/assets/css/iconfont.css";
import Header from "@/components/PC/Header.vue";
import Footer from "@/components/PC/Footer.vue";
import Swiper from "@/components/PC/Swiper.vue";

import "@/style/style.scss";
export default {
  components: {
    Header,
    Footer,
    Swiper,
  },
  data() {
    return {
      list: [
        {
          img: require("@/assets/h5/index/item-crm.png"),
          url: "/product/crm",
          title: "房地产CRM营销管理系统",
        },
        {
          img: require("@/assets/h5/index/item-xcx.png"),
          url: "/product/xcx",
          title: "房地产全民营销小程序",
        },
        {
          img: require("@/assets/h5/index/syzn.png"),
          url: "/product/syzn",
          title: "房地产商业智能",
        },
        {
          img: require("@/assets/h5/index/item-ydxx.png"),
          url: "/product/ydxx",
          title: "移动行销系统",
        },
        {
          img: require("@/assets/h5/index/item-ywlc.png"),
          url: "/product/ywlc",
          title: "业务流程管理系统",
        },

        {
          img: require("@/assets/h5/index/zhgd.png"),
          url: "/product/zhgd",
          title: "智慧工地管理系统",
        },

        {
          img: require("@/assets/h5/index/zxkp.png"),
          url: "/product/zxkp",
          title: "在线开盘系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-招标.png"),
          url: "/product/zbcg",
          title: "招标采购合同管理系统",
        },
        {
          img: require("@/assets/h5/index/item-lfdj.png"),
          url: "/product/lfdj",
          title: "来访登记系统",
        },

        {
          img: require("@/assets/h5/index/index-pro-成本管理.png"),
          url: "/product/cbgl",
          title: "房地产成本管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-拆迁管理.png"),
          url: "/product/cqgl",
          title: "拆迁管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-电话回访.png"),
          url: "/product/dhhf",

          title: "客户电话回访管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-前策.png"),
          url: "/product/qcxt",

          title: "房地产前策系统",
        },
        {
          img: require("@/assets/h5/index/item-fyxcx.png"),
          url: "/product/fyxcx",
          title: "房地产房源展示小程序",
        },

        {
          img: require("@/assets/h5/index/index-pro-渠道管理.png"),
          url: "/product/qdgl",
          title: "渠道管理系统",
        },
        {
          img: require("@/assets/h5/index/index-pro-物业管理.png"),
          url: "/product/wygl",
          title: "物业管理系统",
        },
      ],
      layer: false,
      activeName: "1",
      ddky1: "home_ddky_f",
      ddky3: "home_ddky_f",
      ddky4: "home_ddky_f",
      crm: false,
      xcx: false,
      xxxt: false,
      lfdj: false,
      crmImg1: "crm-img-1",
      crmImg2: "crm-img-2",
      crmTitle: "cpjz-right-title",
      crmRight: "cpjz-right",
      crmMore: "more",

      xcxImg1: "crm-img-1",
      xcxImg2: "crm-img-2",
      xcxTitle: "cpjz-left-title",
      xcxLeft: "cpjz-left",
      xcxMore: "more",

      xxxtImg1: "crm-img-1",
      xxxtImg2: "crm-img-2",
      xxxtTitle: "cpjz-right-title",
      xxxtRight: "cpjz-right",
      xxxtMore: "more",

      lfdjImg1: "crm-img-1",
      lfdjImg2: "crm-img-2",
      lfdjTitle: "cpjz-left-title",
      lfdjLeft: "cpjz-left",
      lfdjMore: "more",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    go(url) {
      this.$router.push({ path: url });
    },
    closeLayer() {
      this.layer = false;
    },
    changeLayer() {
      this.layer = !this.layer;
    },
    changeActive(num) {
      if (num == 1) {
        this.ddky1 = "home_ddky_z";
      } else if (num == 3) {
        this.ddky3 = "home_ddky_z";
      } else if (num == 4) {
        this.ddky4 = "home_ddky_z";
      }
    },
    addActive(num) {
      if (num == 1) {
        this.ddky1 = "home_ddky_f";
      } else if (num == 3) {
        this.ddky3 = "home_ddky_f";
      } else if (num == 4) {
        this.ddky4 = "home_ddky_f";
      }
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;

      if (scrollTop < 1000) {
        this.crmImg1 = "crm-img-1";
        this.crmImg2 = "crm-img-2";
        this.crmTitle = "cpjz-right-title";
        this.crmRight = "cpjz-right";
        this.crmMore = "more";

        this.xcxImg1 = "xcx-img-1 ";
        this.xcxImg2 = "xcx-img-2 ";
        this.xcxTitle = "cpjz-left-title";
        this.xcxLeft = "cpjz-left";
        this.xcxMore = "more";

        this.xxxtImg1 = "crm-img-1 ";
        this.xxxtImg2 = "crm-img-2 ";
        this.xxxtTitle = "cpjz-right-title ";
        this.xxxtRight = "cpjz-right ";
        this.xxxtMore = "more";

        this.lfdjImg1 = "xcx-img-1 ";
        this.lfdjImg2 = "xcx-img-2 ";
        this.lfdjTitle = "cpjz-left-title ";
        this.lfdjLeft = "cpjz-left ";
        this.lfdjMore = "more";

        this.crm = false;
        this.xcx = false;
        this.xxxt = false;
        this.lfdj = false;
      }
      if (scrollTop > 1450) {
        this.crm = true;
        this.crmImg1 = "crm-img-1 l2r-0-5";
        this.crmImg2 = "crm-img-2 l2r-1";
        this.crmTitle = "cpjz-right-title l2r-1";
        this.crmRight = "cpjz-right l2r-2";
        this.crmMore = "more l2r-2";
      }

      if (scrollTop > 2150) {
        this.xcx = true;
        this.xcxImg1 = "xcx-img-1 r2l-2";
        this.xcxImg2 = "xcx-img-2 r2l-0-5";
        this.xcxTitle = "cpjz-left-title r2l-1";
        this.xcxLeft = "cpjz-left r2l-2";
        this.xcxMore = "more r2l-2";
      }

      if (scrollTop > 2850) {
        this.xxxt = true;
        this.xxxtImg2 = "crm-img-2 l2r-2";
        this.xxxtImg1 = "crm-img-1 l2r-0-5";
        this.xxxtTitle = "cpjz-right-title l2r-1";
        this.xxxtRight = "cpjz-right l2r-2";
        this.xxxtMore = "more l2r-2";
      }

      if (scrollTop > 3450) {
        this.lfdj = true;
        this.lfdjImg1 = "xcx-img-1 r2l-2";
        this.lfdjImg2 = "xcx-img-2 r2l-0-5";
        this.lfdjTitle = "cpjz-left-title r2l-1";
        this.lfdjLeft = "cpjz-left r2l-2";
        this.lfdjMore = "more r2l-2";
      }
    },
  },
};
</script>

<style>
body,
html {
  overflow-x: hidden;
}
</style>
<style lang="scss" scoped>
.app {
  background-color: #f5f5f5;
}
.body {
  width: 100%;
  background-color: #f5f5f5;
  .ddky {
    width: 1200px;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    margin-top: 34px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    .left {
      .home_ddky_1 {
        height: 480px;
        width: 440px;
        cursor: pointer;
        transition: all 0.5s;
        .now {
          margin-top: -60px;
          margin-left: -10px;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
          border-top: 48px solid #03958c;
          border-left: 0px solid transparent;
          border-right: 25px solid transparent;
          height: 200px;
          width: 200px;
          position: relative;
          -webkit-animation-name: ti3Action-desc;
          animation-name: ti3Action-desc;
          -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      padding-left: 60px;
      .right-hd {
        .home_ddky_2 {
          margin-top: 50px;
          height: 274px;
          width: 756px;
        }
      }
      .right-fd {
        display: flex;
        justify-content: flex-start;
        .home_ddky_3 {
          margin-top: -166px;
          margin-left: 60px;
          height: 413px;
          width: 330px;
          transition: all 0.6s;
        }

        .home_ddky_4 {
          height: 340px;
          width: 314px;
          transition: all 0.6s;
        }
      }
    }
  }
  .cpjz {
    width: 1200px;
    margin: auto;
    .cpjzimg {
      text-align: center;
      font-size: 16px;
      img {
        margin-top: 110px;
        height: 163px;
        width: 445px;
      }
    }

    .crm {
      margin-top: 86px;
      display: flex;
      justify-content: flex-start;

      .crm-left {
        display: flex;
        justify-content: flex-start;

        .crm-img-1 {
          width: 462px;
          height: 480px;
        }
        .crm-img-2 {
          padding-top: 60px;
          margin-left: -382px;
          width: 540px;
          height: 420px;
        }
      }
      .crm-right {
        animation-name: toleft;
        animation-fill-mode: forwards;
        animation-duration: 2s;
        margin-left: 144px;
        .cpjz-right-title {
          margin-bottom: 40px;
          margin-top: 106px;
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          line-height: 14px;
        }
        .cpjz-right {
          margin-top: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          display: flex;
          justify-content: flex-start;
          .span-line {
            display: block;
            color: #ffffff;
            border-bottom: 4px solid #03958c;
            width: 12px;
            position: relative;
            margin-right: 10px;
          }
        }
        .more {
          margin-top: 60px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #f5b00c;
          line-height: 14px;
        }
      }
    }
  }

  .section {
    background-color: #ffffff;
    .cpjz-white {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .cpjz-left-content {
        display: flex;
        flex-direction: column;
        margin-left: 130px;
        .cpjz-left-title {
          margin-bottom: 40px;
          margin-top: 106px;
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          line-height: 14px;
        }

        .cpjz-left {
          margin-top: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          display: flex;
          justify-content: flex-start;
          .span-line {
            color: #ffffff;
            border-bottom: 4px solid #03958c;
            width: 12px;
            position: relative;
            margin-right: 10px;
          }
        }

        .more {
          margin-top: 60px;
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #f5b00c;
          line-height: 14px;
        }
      }
      .cpjz-right {
        display: flex;
        justify-content: flex-start;
        .xcx-img-1 {
          z-index: 1;
          margin-top: 60px;
          margin-right: -380px;
          width: 648px;
          height: 420px;
        }
        .xcx-img-2 {
          width: 462px;
          height: 480px;
        }
      }
    }
  }

  .dao3 {
    height: 200px;
    width: 100%;
    border-top: 200px solid transparent;
    border-right: 1920px solid #ffffff;
  }
  .zheng3 {
    height: 200px;
    width: 100%;
    border-bottom: 200px solid transparent;
    border-right: 1920px solid #ffffff;
  }
}
.getmore {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  .more {
    margin-top: 2.75rem;
    font-size: 0.67rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ffffff;
    border-top: 2rem solid #03958c;
    border-left: 0px solid transparent;
    border-right: 1.04rem solid transparent;
    height: 2.5rem;
    width: 9.58rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    span {
      text-align: center;
      position: absolute;
      top: -1.46rem;
      left: 1.67rem;
    }
    .jiantou {
      font-size: 1.75rem;
      color: #f5b00c;
      text-align: center;
      position: absolute;
      top: -2.17rem;
      left: 5rem;
    }
  }
}

.maskLayer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  background-color: rgb(0, 0, 0);
  filter: alpha(opacity=60);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  display: flex;
  justify-content: center; /*实现水平居中*/
  align-items: center; /*实现垂直居中*/
  .layer-content {
    height: 80%;
    position: relative; /*脱离文档流*/
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    .close {
      img {
        margin-top: -40px;
        margin-right: -10px;
        width: 60px;
        height: 60px;
      }
    }
    .productList {
      display: flex;
      flex-wrap: wrap;
      flex-flow: row wrap;

      /* !当主轴沿水平方向时!justify-content,决定item在主轴上的对齐方式，可能的值有flex-start（默认），flex-end，center，space-between，space-around */
      justify-content: left;
      /* !主轴水平时!决定了item在交叉轴上的对齐方式，可能的值有flex-start|flex-end|center|baseline|stretch */
      align-items: center;
      text-align: center;
      .list {
        width: 25%;
        height: 25%;
        background: #8db6cd;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 0px 2.08rem 0rem rgba(144, 144, 144, 0.14);
        .item {
          margin: 0 auto;
          text-align: center;
          img {
            padding-top: 10px;

            width: 100px;
            height: 100px;
          }
          .desc {
            padding-bottom: 20px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
