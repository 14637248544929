import axios from '../untils/http'
import QS from 'qs'

/**
 * 发送信息
 */
export function addMsg(data) {
    return axios({
        url: `${process.env.VUE_APP_BASE_URL}/home/addMsg`,
        method: 'post',
        data: QS.stringify(data)
    })
}

/**
 * 发送信息
 */
export function sendEmail(data) {
    return axios({
        url: `${process.env.VUE_APP_BASE_URL}/home/sendEmail`,
        method: 'post',
        data: QS.stringify(data)
    })
}
