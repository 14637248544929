<template>
  <div class="app">
    <Header :active="activeName"></Header>
    <div class="banner" :style="bannerStyle">
      <div class="background">
        <div class="content">
          <div class="left">
            <div class="name">
              <i><b>ABCOUT</b></i>
            </div>
            <div class="title">
              <div>关于我们</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tip">
      <img src="@/assets/about/tip.png" />
      <div class="desc">
        <span>一群认真专注的工程师和一个个伟大的产品构想。</span>
        <span>
          成都扬帆启初科技有限公司成立于2018年，成员来自于上市公司核心团队，致力于金融，房产IT服务及智慧物联网产品的设计研发，我们秉持着简明、坚定的信念
          :</span
        >
        提供创新与便捷的产品，并佐以贴心的客户服务，提供高质的产品及服务，提升客户的便利性。这是我们的理念，为此，我们不断向前迈进！
        <span> </span>
      </div>
    </div>
    <img class="descImg" src="@/assets/about/desc.png" />

    <div class="dream">
      <div class="left">
        <img src="@/assets/about/dream.png" alt="" />
        <div class="text">
          客户的满意是启初设计产品的宗旨，我们要让您相信启初的产品物超所值-高阶的产品，高质的服务！
        </div>
      </div>
      <div class="right">
        <img src="@/assets/about/slogan.png" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Header from "@/components/PC/Header.vue";
import Footer from "@/components/PC/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  // 定义属性
  data() {
    return {
      activeName: "3",
      bgImgUrl: require("@/assets/about/banner.png"),
      bannerStyle: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "100% 100%",
      },
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    //...mapGetters(['userPermissions','buttonType'])
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.bannerStyle.backgroundImage = " url(" + this.bgImgUrl + ")";
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="scss" scoped>
.app {
  background-color: #f5f5f5;
  .banner {
    background-color: transparent;
    .bgImg {
      height: 100%;
      width: 100%;
    }
    .background {
      height: 660px;
      width: 100%;
      .content {
        padding-top: 220px;
        width: 1200px;
        margin: 0 auto;
        font-size: 20px;
        color: #fff;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        .left {
          display: flex;
          flex-direction: column;
          .name {
            margin-left: 100px;
            height: 17px;
            font-size: 24px;
            font-family: Helvetica-BoldOblique;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            opacity: 0.3;
          }

          .title {
            margin-top: 40px;
            margin-left: 100px;

            height: 45px;
            font-size: 48px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            line-height: 34px;
            span {
              color: #03958c;
            }
          }
        }
      }
    }
  }

  .tip {
    margin: 0 auto;
    text-align: center;
    margin-top: 108px;
    .desc {
      text-align: center;
      margin: 0 auto;
      margin-top: 40px;
      width: 1093px;
      height: 70px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      span {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  .descImg {
    margin-top: 130px;
    border: 1px solid #000;
    float: lef;
  }

  .dream {
    padding-top: 100px;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      img {
        width: 359px;
        height: 157px;
      }
    }
    .right {
      img {
        width: 500px;
        height: 338px;
      }
    }
  }
}
</style>
